import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { plan_credit, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan_credit.addItem({ ...fields, 'plan_type': 'buy_credit', 'status': 1});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param plan_id
 */
const handleUpdate = async (fields, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan_credit.updateItem({ ...fields, id: plan_id, 'plan_type': 'buy_credit'});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 *  Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await plan_credit.deleteItems({
            lists: selectedRows.map((row) => row.plan_id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan_credit.changeStatus(record.plan_id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Show
 *
 * @param show
 * @param record
 */
const handledChangeShow = async (show, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan_credit.changeShow(record.plan_id, {'is_show': show ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const PlanCreditComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await plan_credit.getList(parameters);
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Name",
            dataIndex: 'plan_name',
            sorter: false,
            valueType: 'text',
            initialValue: paramQuery?.plan_name,
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">{dom}</span>
                    </a>
                );
            }
        },
        {
            title:'Number Credit',
            dataIndex: 'plan_daily_limit',
            hideInSearch: true,
        },
        {
            title:'Price',
            dataIndex: 'plan_price',
            hideInSearch: true,
            render: (_, record) => [
                <span key="plan_price">${ record.plan_price }</span>
            ]
        },
        {
            title:'Sort',
            dataIndex: 'sort',
            hideInSearch: true,
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueType: 'select',
            initialValue: paramQuery?.status,
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" unCheckedChildren="Deactive" onChange={(value) => handledChangeStatus(value, record)} key={record.plan_id} defaultChecked={record.status  == 1 ? true : false} />
            ]
        },
        {
            title: "Show",
            dataIndex: 'is_show',
            valueType: 'select',
            initialValue: paramQuery?.is_show,
            valueEnum: {
                0: {
                    text:'Not Show',
                    status: 'Default',
                },
                1: {
                    text: "Show",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => handledChangeShow(value, record)} key={record.plan_id} defaultChecked={record.is_show  == 1 ? true : false} />
            ]
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a  key="edit"
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        form.setFieldsValue(record);
                        handleModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.plan_id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><DeleteOutlined /> Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Plans Credit Manager"
                actionRef={actionRef}
                request={list}
                rowKey="plan_id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            form.resetFields();
                            setCurrentRow(null);
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>,
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
            />

             {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new*/}
            <ModalForm
                id="form-plan-credit"
                title="Add Form Plan Credit"
                width="500px"
                form={form}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    if (currentRow?.plan_id) {
                        const success = await handleUpdate(value, currentRow?.plan_id);
                        if (success) {
                            form.resetFields();
                            handleModalVisible(false);
                            if (actionRef.current) {
                                actionRef.current.reload();
                            }
                        }
                    } else {
                        const success = await handleAdd(value);
                        if (success) {
                            form.resetFields();
                            handleModalVisible(false);
                            if (actionRef.current) {
                                actionRef.current.reload();
                            }
                        }
                    }
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    value={currentRow?.plan_name}
                    width="lg"
                    name="plan_name"
                    label="Name:"
                    placeholder="Name"
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Number Point is required"
                        },
                    ]}
                    value={currentRow?.plan_daily_limit}
                    label="Number Point" 
                    placeholder="Number Point" 
                    name="plan_daily_limit" 
                    width="lg" 
                    min={0}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Price is required"
                        },
                    ]}
                    value={currentRow?.plan_price}
                    label="Price($)" 
                    placeholder="Price($)" 
                    name="plan_price" 
                    width="lg" 
                    min={0}
                    step="0.01"
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Sort is required"
                        },
                    ]}
                    value={currentRow?.sort}
                    label="Sort" 
                    name="sort" 
                    width="100%" 
                    min={0}
                />
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.plan_id && (
                    <ProDescriptions 
                        title={currentRow?.user_name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.plan_id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default PlanCreditComponent;
