import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Button} from 'antd';
import mostPopular from '../../../assets/theme/images/most_popular.png';
import CountdownTimer from './CountdownTimer';

const DailyItem = ({item, listData, profile}) => {
    const history = useHistory();
    const [dayShow, setDayShow] = useState(30);
    const [currentPlan, setCurrentPlan] = useState(30);
    const [listDay, setListDay] = useState([30]);
    const [isModalVisiblePlan, setIsModalVisiblePlan] = useState(false);

    /**
     * Handle change day
     *
     * @param event
     */
    const onChangeDay = (event) => {
        let day = event.target.value;
        if (listData.length > 0) {
            for (let index = 0; index < listData.length; index++) {
                const element = listData[index];
                if (element.plan_daily_limit == currentPlan?.plan_daily_limit && element.plan_expired == day) {
                    setCurrentPlan(element);
                }
            }
        }
        setDayShow(day);
    }

    /**
     * Handle load list day
     * 
     * @param current
     */
    const loadListDay = (current) => {
        const list = [];
        if (listData.length > 0) {
            for (let index = 0; index < listData.length; index++) {
                const element = listData[index];
                if (element.plan_daily_limit == current?.plan_daily_limit && element?.is_trial != 1) {
                    list.push(element.plan_expired);
                }
            }
        }
        setListDay(list.sort());
    }

    /**
     * Check buy another
     */
    const checkBuyAnother = () => {
        if (profile?.plan_type != "None" && !(profile?.plan_type == currentPlan.plan_type && profile?.plan_daily_limit == currentPlan.plan_daily_limit)) {
            setIsModalVisiblePlan(true);
        } else {
            history.push({pathname: '/payment/' + currentPlan?.plan_id});
        }
    }

    /**
     * Handle buy another
     */
    const handleBuyAnother = () => {
        setIsModalVisiblePlan(false);
        history.push({pathname: '/payment/' + currentPlan?.plan_id});
    }

    /**
     * Handle calculater discount
     */
    const handledCalculaterDiscount = () => {
        if (currentPlan?.discount) {
            return currentPlan?.plan_price - ((currentPlan?.plan_price * currentPlan?.discount.discount)/100);
        }
        return currentPlan?.plan_price;
    }

    useEffect(() => {
        setCurrentPlan(item);
        loadListDay(item);
    }, [item, listData, profile]);

    return (
        <>
            {
                currentPlan?.is_feature == 1 && (
                    <div className="activity-img-right popular__activity_img">
                        <img src={mostPopular} width="112px" height="43px" />
                    </div>
                )
            }
            <div className="package-item-content">
                <div className="package-detail">
                    <div className="package-num">
                        <span className="package-number">
                            <span className="residential__num">{currentPlan?.plan_name}</span>
                        </span>
                    </div>
                    <div className="package-price mt-2">
                        <span>$</span>
                        <span className="residential__total">{handledCalculaterDiscount()}</span>
                        {
                            currentPlan?.discount ? (
                                <span className="package-original-price ml-1 text-danger">
                                    <span>$</span>
                                    <span className="residential__original">{currentPlan?.plan_price}</span>
                                </span>
                            ) : ""
                        }
                    </div>
                </div>
                <div className="package-adv">
                    <div className="adv-item">
                        <div className="counter">
                            <select className="input_number" defaultValue={dayShow} onChange={onChangeDay}>
                                {
                                    listDay && listDay.length > 0 && listDay.map((day, key) => 
                                        <option value={day} key={"day" + day}>{day}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="day">Days</div>
                    </div>
                    <div className="adv-item">
                        <span className="adv-item-tit">Expiry date</span>
                        <span className="adv-item-detail">
                            <span className="residential__all_flow">{currentPlan?.plan_expired == 0 ? 'Unlimited' : currentPlan?.plan_expired + ' Days'}</span>
                        </span>
                    </div>
                    <div className="adv-item">
                        <span className="adv-item-tit">Daily limit</span>
                        <span className="adv-item-detail">
                            <span>{currentPlan?.plan_daily_limit != 0 ? currentPlan?.plan_daily_limit : 'Unlimited'}</span>
                        </span>
                    </div>
                    <div className="adv-item">
                        <span className="adv-item-tit">Total Socks5 Receive</span>
                        <span className="adv-item-detail">
                            <span>{currentPlan?.total_receive}</span>
                        </span>
                    </div>
                    <div className="adv-item">
                        <span className="adv-item-tit">Reset Limit everyday</span>
                        <span className="adv-item-detail">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                            </svg>
                        </span>
                    </div>
                    <div className="adv-item">
                        <span className="adv-item-tit">Country selection</span>
                        <span className="adv-item-detail">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className="adv-item">
                        <span className="adv-item-tit">Get List socks5</span>
                        <span className="adv-item-detail daily-list-socks5">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="16" height="20" viewBox="0 0 64.000000 71.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,71.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                        <path d="M275 696 c-53 -13 -90 -30 -115 -52 -8 -7 -32 -28 -52 -45 -21 -17 -35 -35 -32 -40 3 -5 1 -9 -5 -9 -6 0 -21 -19 -33 -42 -19 -35 -23 -59 -23 -128 0 -91 10 -120 67 -192 26 -34 28 -40 18 -80 -5 -24 -10 -53 -10 -65 0 -22 1 -22 58 9 50 27 68 31 142 32 65 1 96 7 132 24 58 27 121 81 114 97 -3 8 2 11 15 8 11 -3 19 0 19 6 0 7 9 24 20 39 11 15 26 52 34 83 42 165 -80 333 -264 362 -19 3 -57 0 -85 -7z m-37 -169 c7 -9 18 -14 24 -11 6 4 8 3 5 -3 -4 -6 4 -20 16 -31 l22 -21 38 35 c20 20 35 39 33 42 -2 4 8 7 24 7 17 0 26 -4 23 -12 -5 -14 19 -43 36 -43 22 0 10 -25 -31 -67 l-42 -43 39 -40 c21 -21 42 -37 48 -33 19 11 -12 -33 -45 -65 l-34 -32 -45 45 -44 44 -45 -44 -46 -45 -39 40 -40 41 47 43 46 44 -44 41 c-48 44 -48 56 -5 95 11 10 18 22 16 26 -10 16 31 4 43 -13z"/>
                                    </g>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="package-btn">
                    <a onClick={checkBuyAnother} className="btn residential__pay_btn">Buy Plan</a>
                    {
                        item?.discount ? (
                            <span className="actlabel_coner d-block text-danger mt-1">SALE OFF {item?.discount.discount}%</span>
                        ) : ""
                    }
                    {
                        item?.discount && item?.discount?.end_date ? (
                            <CountdownTimer targetDate={item?.discount.end_date} show="Expired" />
                        ) : ""
                    }
                </div>
            </div>
            <Modal visible={isModalVisiblePlan} footer={null} closable={false} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <div className='alert alert-warning'>WARNING!</div>
                        <p>You're current using plan ({profile?.plan_current_name}), so you only renew plan ({currentPlan?.plan_name}), can't upgrade new plan.</p>
                        <p>If you buy another plan , Old plan will be canceled please waiting for the current plan to expire.</p>
                        <p>If you are sure you want to buy another package, please click buy another package</p>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleBuyAnother} className="d-block medium">Buy another package</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={() => setIsModalVisiblePlan(false) } className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default DailyItem;
