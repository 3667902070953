import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Select, InputNumber, Checkbox, Spin, notification, message, Modal } from 'antd';
import { sock_list, account, banner, authenticator } from '../../endpoint';
import { UndoOutlined } from '@ant-design/icons';
import { copyTextToClipboard } from '../../_until';

const Sock5List = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [dataCountry, setDataCountry] = useState([]);
    const [dataState, setDataState] = useState([]);
    const [dataCity, setDataCity] = useState([]);
    const [dataLists, setDataLists] = useState([]);
    const [profile, setProfile] = useState([]);
    const [dataListBanner, setListBanner] = useState([]);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [dataAds, setBannerAds] = useState([]);
    const [fillSock, setfillSock] = useState(false);
    const [first, setFirst] = useState(true);
    const [isResetFree, setIsResetFree] = useState(0);
    const [isReset, setIsReset] = useState(0);
    const [useToday, setUseToday] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFreeVisible, setIsModalFreeVisible] = useState(false);
    const [checked, setChecked] = useState(false);
    const [listRichLevel, setListRichLevel] = useState([
        {value: 1, label: 'Low'},
        {value: 2, label: 'Medium'},
        {value: 3, label: 'Hight'},
        {value: 4, label: 'Very High'}
    ]);
    const [listRichLevelAll, setListRichLevelAll] = useState([]);
    const [formatIpPort, setFormatIpPort] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle Search form
     *
     * @param values
     */
    const onFinish = (values) => {
        if (profile?.plan_type != "List") {
            notification.open({
                message: 'Fail!',
                description: "Please buy plan Socks5 List before viewing this item.",
                type: 'error'
            });
            return false;
        }

        const hide = message.loading('Processing...');
        setLoading(true);
        if (checked) {
            values.is_live = 1;
        }
        setfillSock(false);
        setFormatIpPort(false);
        sock_list.getSockList(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setIsReset(res.is_reset);
                setIsReset(res.is_reset_free);
                setUseToday(res.used_today);
                setFirst(false);
            } else if (res.status == 'fail') {
                notification.open({
                    message: res?.subtitle,
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    };

    /**
     * Handle check live
     *
     * @param e
     */
    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    /**
     * Handle close modal reset
     */
    const handleCancelReset = () => {
        setIsModalVisible(false);
    };

    /**
     * Handle close modal reset free
     */
    const handleCancelFree = () => {
        setIsModalFreeVisible(false);
    };

    /**
     * Handle show modal reset
     */
    const showModalReset = () => {
        setIsModalVisible(true);
    }

    /**
     * Handle show modal reset free
     */
    const showModalFree = () => {
        setIsModalFreeVisible(true);
    }

    /**
     * Handle reset plan
     */
    const resetPlan = () => {
        setLoadingButton(true);
        account.reset_plan({is_free: isModalFreeVisible ? 1 : 0}).then(res => {
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Reset successfully',
                    type: 'success'
                });
                handleCancelReset();
                handleCancelFree();
                location.reload();
            } else {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
            setLoadingButton(false);
        }).catch(err => {
            setLoadingButton(false);
            notification.open({
                message: 'Fail!',
                description: "Error",
                type: 'error'
            });
        });
    }

    /**
     * Handle change type sock
     *
     * @param values
     */
    const changeTypeSock = (values) => {
        form.setFieldsValue({'sock_country': 'all'});
        form.setFieldsValue({'sock_state': 'all'});
        form.setFieldsValue({'sock_city': 'all'});
        form.setFieldsValue({'level': ''});
        sock_list.getCountry({all: 1, type: values}).then(res => {
            if (res.status == 'success') {
                setDataCountry(res.response);
                setDataState({});
                setDataCity({});
            }
        });

        sock_list.getRickLevel({all: 1, type: values}).then(res => {
            if (res.status == 'success') {
                setListRichLevel(res.response);
            }
        });
    }

    /**
     * Handle change rick level
     *
     * @param rickLevel
     */
    const changeRichLevel = (rickLevel) => {
        let type = form.getFieldValue('type_sock');
        form.setFieldsValue({'sock_country': 'all'});
        form.setFieldsValue({'sock_state': 'all'});
        form.setFieldsValue({'sock_city': 'all'});
        sock_list.getCountry({all: 1, type: type, 'rick_level': rickLevel}).then(res => {
            if (res.status == 'success') {
                setDataCountry(res.response);
                setDataState({});
                setDataCity({});
            }
        });
    }

    /**
     * Handle change country
     *
     * @param values
     */
    const changeCountry = (values) => {
        let type = form.getFieldValue('type_sock');
        let rickLevel = form.getFieldValue('level');
        sock_list.getState({all: 1, sock_country: values, 'type': type, 'rick_level': rickLevel}).then(res => {
            if (res.status == 'success') {
                setDataState(res.response);
            }
        });
    }

    /**
     * Handle change state
     *
     * @param values
     */
    const changeState = (values) => {
        let type = form.getFieldValue('type_sock');
        let rickLevel = form.getFieldValue('level');
        let country = form.getFieldValue('sock_country');
        form.setFieldsValue({'sock_city': 'all'});
        sock_list.getCity({all: 1, sock_country: country, sock_states: values, 'type': type, 'rick_level': rickLevel}).then(res => {
            if (res.status == 'success') {
                setDataCity(res.response);
            }
        });
    }

    /**
     * Handle show fill sock
     */
    const showFill = () => {
        setFormatIpPort(false);
        let textFill = '';
        for (let index = 0; index < dataLists.length; index++) {
            const item = dataLists[index];
            if (item.user_name != null) {
                textFill = textFill + item.sock_ip + ':' + item.user_name + ":" + item.password + '\n';
            } else {
                textFill = textFill + item.sock_ip + '\n';
            }
        }
        $('.section-fillsock textarea').val(textFill);
        setfillSock(true);
    }

    /**
     * Handle copy fill sock
     */
    const copyShowFill = () => {
        let textFill = '';
        for (let index = 0; index < dataLists.length; index++) {
            const item = dataLists[index];
            if (item.user_name != null) {
                textFill = textFill + item.sock_ip + ':' + item.user_name + ":" + item.password + '\n';
            } else {
                textFill = textFill + item.sock_ip + '\n';
            }
        }
        if (copyTextToClipboard(textFill)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Handle copy sock
     *
     * @param sock
     */
    const copySock = (sock) => {
        if (copyTextToClipboard(sock)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Handle copy text
     *
     * @param textCopy
     */
    const copyText = (textCopy) => {
        if (copyTextToClipboard(textCopy)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Handle fill Format Ip Port
     */
    const fillFormatIpPort = () => {
        setfillSock(false);
        setFormatIpPort(!formatIpPort);
    }

    /**
     * Handle close modal ads
     */
    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    /**
     * Handle redirect ads
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    /**
     * Handle reset form search
     */
    const onResetForm = () => {
        if (profile?.plan_type != "List") {
            notification.open({
                message: 'Fail!',
                description: "Please buy plan Socks5 List before viewing this item.",
                type: 'error'
            });
            return false;
        }
        form.resetFields();
        setListRichLevel(listRichLevelAll);
    }

    /**
     * Handle get level
     *
     * @param level
     */
    const getLevel = (level) => {
        if (level == 1) {
            return "Low";
        } else if (level == 2) {
            return "Medium";
        } else if (level == 3) {
            return "Hight";
        } else if (level == 4) {
            return "Very High";
        }
        return "";
    }

    useEffect(() => {
        document.title = "Socks5 List - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                            setIsResetFree(res.response.is_reset_free);
                            setIsReset(res.response.is_reset);
                            setUseToday(res.response.count);
                        }
                    }).then(err => {
                        setLoadingProfile(false);
                    }).then(complete => {
                        setLoadingProfile(false);
                    });

                    sock_list.getCountry({all: 1}).then(res => {
                        if (res.status == 'success') {
                            setDataCountry(res.response);
                        }
                    }).then(err => {
                        setLoading(false);
                    }).then(complete => {
                        setLoading(false);
                    });

                    banner.get_list_banner({position: 'sock'}).then(res => {
                        if (res.status == 'success') {
                            setListBanner(res.response);
                        }
                    });
                }
            }).then(res => {
            });

            // sock_list.getState({all: 1}).then(res => {
            //     if (res.status == 'success') {
            //         setDataState(res.response);
            //     }
            // });

            // sock_list.getCity({all: 1}).then(res => {
            //     if (res.status == 'success') {
            //         setDataCity(res.response);
            //     }
            // });
        }
        setListRichLevelAll(listRichLevel);
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    /**
     * Show list banner
     */
    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full != 0 ? (12/item.is_full) : '12')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row align-items-center">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="content search-socks-list">
                {
                    loadingProfile ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div>
                            <div className="form-search">
                                <div className="padding-10">
                                    <Form
                                        name="basic"
                                        form={form}
                                        className="form-horizontal mt-3"
                                        onFinish={onFinish}
                                    >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <h5><b>Type Socks</b></h5>
                                                            <Form.Item name="type_sock">
                                                                <Select
                                                                    style={{width: "100%"}}
                                                                    onChange={changeTypeSock}
                                                                    placeholder="All"
                                                                >
                                                                    <Option value="">All</Option>
                                                                    <Option value="business">Business</Option>
                                                                    <Option value="cellular">Cellular</Option>
                                                                    <Option value="hosting">Hosting</Option>
                                                                    <Option value="residential">Residential</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <h5><b>Search Sock</b></h5>
                                                            <Form.Item name="sock_country">
                                                                <Select
                                                                    showSearch
                                                                    style={{width: "100%"}}
                                                                    placeholder="All Country"
                                                                    onChange={changeCountry}
                                                                    filterOption={(input, option) =>
                                                                        (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase()) ||
                                                                        (input.length == 2 && option.props.value.toLowerCase() == input.toLowerCase())
                                                                    }
                                                                >
                                                                    <Option value="all">All Country</Option>
                                                                    {
                                                                        dataCountry.length  > 0 && dataCountry.map((item, key) => 
                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                            <Form.Item name="sock_state">
                                                                <Select
                                                                    showSearch
                                                                    style={{width: "100%"}}
                                                                    placeholder="All State"
                                                                    onChange={changeState}
                                                                    filterOption={(input, option) =>
                                                                        (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase())
                                                                    }
                                                                >
                                                                    <Option value="all">All State</Option>
                                                                    {
                                                                        dataState.length  > 0 && dataState.map((item, key) => 
                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                            <Form.Item name="sock_city">
                                                                <Select
                                                                    showSearch
                                                                    style={{width: "100%"}}
                                                                    placeholder="All City"
                                                                    filterOption={(input, option) =>
                                                                        (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase())
                                                                    }
                                                                >
                                                                    <Option value="all">All City</Option>
                                                                    {
                                                                        dataCity.length  > 0 && dataCity.map((item, key) => 
                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                            <Form.Item name="black_list">
                                                                <Select
                                                                    style={{width: "100%"}}
                                                                    placeholder="Black List"
                                                                >
                                                                    <Option value="">All</Option>
                                                                    <Option value="0">Clear</Option>
                                                                    <Option value="1">BlackList</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <h5><b>Search Zipcode</b></h5>
                                                        <div className="form-group">
                                                            <Form.Item name="sock_zipcode">
                                                                <Input style={{width: "100%"}} name="txtZip" placeholder="Search Zipcode"/>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <h5><b>ISP Name</b></h5>
                                                <div className="row">
                                                    <div className="form-group col-sm-12">
                                                        <div className="form-group">
                                                            <Form.Item name="sock_hostname">
                                                                <Input style={{width: "100%"}} name="txtHostName" placeholder="Search ISP Name"/>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <h5><b>Passowrd</b></h5>
                                                <div className="row">
                                                    <div className="form-group col-sm-12">
                                                        <div className="form-group">
                                                            <Form.Item name="is_password">
                                                                <Select
                                                                    style={{width: "100%"}}
                                                                    placeholder="Passowrd"
                                                                >
                                                                    <Option value="">All</Option>
                                                                    <Option value="1">Yes</Option>
                                                                    <Option value="0">No</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <h5><b>VPN</b></h5>
                                                <div className="row">
                                                    <div className="form-group col-sm-12">
                                                        <div className="form-group">
                                                            <Form.Item name="is_vpn">
                                                                <Select
                                                                    style={{width: "100%"}}
                                                                    placeholder="VPN"
                                                                >
                                                                    <Option value="">All</Option>
                                                                    <Option value="1">Yes</Option>
                                                                    <Option value="0">No</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <h5><b>Number Socks</b></h5>
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <Form.Item 
                                                            name="txtNumber" 
                                                            rules={[{ required: true, message: 'Please input Number Socks!' }]}
                                                        >
                                                            <InputNumber style={{width: "100%"}} name="txtNumber" min={1} placeholder="Number Socks"/>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <h5 className='d-none d-md-block'><b>&nbsp;</b></h5>
                                                <div className="text-center">
                                                    <Form.Item name="is_live">
                                                        <Checkbox checked={checked} onChange={onChange}>
                                                            Check socks5 live (Check max 10socks5)
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-2">
                                                <div className="col-auto">
                                                    <Button type="primary" htmlType="submit" loading={loading} className="d-block medium mb-3">Search</Button>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="col-auto">
                                                    <Button type="primary" htmlType="button" onClick={onResetForm} className="d-block medium mb-3">Clear</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="mb-3 text-center">Please enter the number of socks in the box Number Socks and press search you will see the list socks5 online</p>
                                    </Form>
                                    <p className='text-center mb-0'><b>Plan:</b> <b className='text-success'>{profile?.plan_current_name}</b></p>
                                    <p className='text-center mb-3'><b>Expired:</b> <b className='text-success'>{profile?.user_exdate} ({profile?.user_exdate_left})</b></p>
                                    <h4 className="text-center mb-3">Used today: {useToday}</h4>
                                    {
                                        profile?.plan_type == 'List' ? (
                                            profile?.is_show_reset == 1 ? (
                                                <div className='text-center'>
                                                    {
                                                        isReset == 1 && (
                                                            <Button type="primary" onClick={() => showModalReset()} htmlType="submit" className="medium text-white mr-3">
                                                                Reset <UndoOutlined className="site-form-item-icon" />
                                                            </Button>
                                                        )
                                                    }
                                                    {
                                                        isResetFree == 1 ? (
                                                            <Button type="primary" onClick={() => showModalFree()} htmlType="submit" className="medium text-white mr-3">
                                                                Reset Free <UndoOutlined className="site-form-item-icon" />
                                                            </Button>
                                                        ) : ''
                                                    }
                                                </div>
                                            ) : (
                                                useToday == profile.limit ? (
                                                    <div className='text-center mb-3'>
                                                        <p className='mb-0'>"Your account limited today!". You can reset limit at <Link to="/my-account.html">My account</Link></p>
                                                    </div>
                                                ) : ""
                                            )
                                        ) : ''
                                    }
                                </div>
                            </div>
                            <div className="panel panel-default panel-custom">
                                <div className="panel-heading">Socks5 List</div>
                                <div className="panel-body">
                                    <div className="table-responsive">
                                        {
                                            loading ? (
                                                <div className="text-center mt-5 mb-5"><Spin /></div>
                                            ) :
                                            (
                                                <table className="table table-striped table-sock-list table-list-mobile">
                                                    <thead>
                                                        <tr>
                                                            <th>Sock5</th>
                                                            <th>Proxy Type</th>
                                                            <th>Country</th>
                                                            <th>Region</th>
                                                            <th>City</th>
                                                            <th>Zipcode</th>
                                                            <th>ISP Name</th>
                                                            <th>Type Sock</th>
                                                            <th>Spam Mail</th>
                                                            <th>VPN</th>
                                                            <th>Online</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            dataLists.length  > 0 && dataLists.map((item, key) => 
                                                                <tr key={item.sock_id}>
                                                                    <td data-title="Sock5" className="sock">
                                                                        {
                                                                            item.user_name != null ? 
                                                                                (<a onClick={() => copyText(item.sock_ip + ':' + item.user_name + ':' + item.password)}>{item.sock_ip + ':' + item.user_name + ':' + item.password}</a>) :
                                                                                (<a onClick={() => copySock(item.sock_ip)}>{item.sock_ip}</a>)
                                                                        }
                                                                    </td>
                                                                    <td data-title="Proxy Type">{item.proxy_type == 'proxy_https' ? 'HTTPS Proxy' : 'SOCKS5 Proxy'}</td>
                                                                    <td data-title="Country">
                                                                        <img src={item.country_img} />&nbsp;&nbsp;
                                                                        {item.sock_country}
                                                                    </td>
                                                                    <td data-title="Region">{item.sock_states}</td>
                                                                    <td data-title="City">{item.sock_city}</td>
                                                                    <td data-title="Zipcode">{item.sock_zip}</td>
                                                                    <td data-title="ISP Name" className="host">{item.sock_hostname}</td>
                                                                    <td data-title="Type Sock" className="text-capitalize">{item.type_sock}</td>
                                                                    <td data-title="Spam Mail">{item.sock_back_list == '1' ? 'BlackList' : 'No'}</td>
                                                                    <td data-title="VPN">{item.vpn == '1' ? 'Yes' : 'No'}</td>
                                                                    <td data-title="Online">{item.sock_times}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                            <td colSpan="11" className="text-center no-padding">
                                                                Please wait website update new socks5.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    </div>
                                    {
                                        dataLists && dataLists.length > 0 ? (
                                            <div className="text-right mt-3">
                                                <Button type="primary" htmlType="button" onClick={() => copyShowFill()} className="medium mr-3">Copy List Socks5</Button>
                                                <Button type="primary" htmlType="button" onClick={() => showFill()} className="medium mr-3">Fill Sock</Button>
                                                <Button type="primary" htmlType="button" onClick={() => fillFormatIpPort()} className="medium">Format IP:PORT</Button>
                                            </div>
                                        ) : ''
                                    }
                                    <div className='mt-3 section-fillsock'>
                                        <a onClick={() => copyShowFill()} className={fillSock ? "copy-fillsock" : "d-none copy-fillsock"} ><i className="fa fa-copy" aria-hidden="true"></i></a>
                                        <textarea className={fillSock ? "form-control" : "d-none form-control"} rows={10}></textarea>
                                    </div>
                                    {
                                        formatIpPort ? (
                                            <div className='mt-3 section-fillsock'>
                                                <h5><b>Format IP:PORT</b></h5>
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-sock-list table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>IP</th>
                                                                <th>Port</th>
                                                                <th>User Name</th>
                                                                <th>Password</th>
                                                                <th>Country</th>
                                                                <th>Region</th>
                                                                <th>City</th>
                                                                <th>Zipcode</th>
                                                                <th>ISP Name</th>
                                                                <th>Type Sock</th>
                                                                <th>Spam Mail</th>
                                                                <th>VPN</th>
                                                                <th>Online</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                                    <tr key={item.sock_id}>
                                                                        <td data-title="IP">
                                                                            <a onClick={() => copyText(item.sock_ip.split(":")[0])}>{item.sock_ip.split(":")[0]}</a>
                                                                        </td>
                                                                        <td data-title="Port">
                                                                            <a onClick={() => copyText(item.sock_ip.split(":")[1])}>{item.sock_ip.split(":")[1]}</a>
                                                                        </td>
                                                                        <td data-title="User Name">{item.user_name}</td>
                                                                        <td data-title="Password">{item.password}</td>
                                                                        <td data-title="Country">
                                                                            <img src={item.country_img} />&nbsp;&nbsp;
                                                                            {item.sock_country}
                                                                        </td>
                                                                        <td data-title="Region">{item.sock_states}</td>
                                                                        <td data-title="City">{item.sock_city}</td>
                                                                        <td data-title="Zipcode">{item.sock_zip}</td>
                                                                        <td data-title="ISP Name" className="host">{item.sock_hostname}</td>
                                                                        <td data-title="Type Sock" className="text-capitalize">{item.type_sock}</td>
                                                                        <td data-title="Spam Mail">{item.sock_back_list == '1' ? 'BlackList' : 'No'}</td>
                                                                        <td data-title="VPN">{item.vpn == '1' ? 'Yes' : 'No'}</td>
                                                                        <td data-title="Online">{item.sock_times}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
            <Modal title="Reset Account" visible={isModalVisible} onCancel={handleCancelReset} footer={null} width={580}>
                <p className="mb-3">
                    If you use reset limit function<br/>
                    You agree to deduct 1 day of usage to remove "Your account limited today!"<br/><br/>
                    You are using the Reset Limit function, your account is currently on:<br/>
                    Plan ({profile?.plan_name}) | Expires on: {profile?.user_exdate}.<br/>
                    When you click "Agree", you agree to deduct 1 day of usage, your account will be changed to:<br/>
                    Plan ({profile?.plan_name}) | Expires on: {profile?.user_exdate_1day}.<br/><br/>
                    What is "Your account limited today!"?<br/>
                    - This message usually appears when you have reached the usage limit for your account.<br/>
                    - The limit can be on the number of socks5 you can use per day or on the total amount of data you can use.<br/>
                    - You can check your account details in <a href="/my-account.html">My Account</a> to see the specific limits for your account.<br/>
                    - If you have reached your limit, you will need to wait until the limit resets before you can continue using the service.<br/>
                    - We will arrive at the new date at 0:00 AM on the GMT+7 time zone. You can see the GMT+7 time zone at the link: https://time.is/en/UTC+7<br/><br/>
                    U can see reset limit history at link:<br/>
                    <a href='/reset.html'>https://shopsocks5.com/reset.html</a>
                </p>
                <p className="text-center">
                    <Button type="primary" onClick={() => resetPlan()} htmlType="submit" loading={loadingButton} className="medium text-white mr-3">
                        Agree
                    </Button>
                    <Button type="danger" onClick={() => handleCancelReset()} htmlType="submit" className="medium">
                        Cancel
                    </Button>
                </p>
                <p className="text-center mt-3">
                    <span className="text-danger"><b>Support</b></span><br/> 
                    Email: {window.confirm?.support_email}<br/> 
                    Skype: {window.confirm?.skype}
                </p>
            </Modal>
            <Modal title="Reset Free Account" visible={isModalFreeVisible} onCancel={handleCancelFree} footer={null} width={580}>
                <p className="text-center mb-3"><b>Your account has 1 free reset every month<br/>You want reset your account today?</b></p>
                <p className="text-center">
                    <Button type="primary" onClick={() => resetPlan()} htmlType="submit" loading={loadingButton} className="medium text-white mr-3">
                        Agree
                    </Button>
                    <Button type="danger" onClick={() => handleCancelFree()} htmlType="submit" className="medium">
                        Cancel
                    </Button>
                </p>
                <p className="text-center mt-3">
                    <span className="text-danger"><b>Support</b></span><br/> 
                    Email: {window.confirm?.support_email}<br/> 
                    Skype: {window.confirm?.skype}
                </p>
            </Modal>
        </div>
    );
}
export default Sock5List;
