import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, Upload } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { partner, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Add node
 *
 * @param fields
 * @param logo
 */
const handleAdd = async (fields, logo) => {
    const hide = message.loading('Processing...');
    try {
        const formData = new FormData();
        formData.append("image", logo, logo.name);
        Object.keys(fields).map((key, index) => {
            formData.append(key, fields[key]);
        });
        var res = await partner.addItem(formData);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param logo
 * @param id
 */
const handleUpdate = async (fields, logo, id) => {
    const hide = message.loading('Processing...');
    try {
        const formData = new FormData();
        if (logo) {
            formData.append("image", logo, logo.name);
        }
        Object.keys(fields).map((key, index) => {
            formData.append(key, fields[key]);
        });
        formData.append('id', id);
        var res = await partner.updateItem(formData);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await partner.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await partner.changeStatus(record.id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const PartnerComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [file, setFile] = useState(null);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};
        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');
        history.push(`?` + queryString);
        const data = await partner.getList(parameters);
        return data;
    }

    /**
     * Load list plan api
     */
    const categories = async () => {
        const data = await partner.getCategorySelect();
        return data;
    }

    /**
     * Handle change logo
     *
     * @param file
     */
    const onChangeLogo = (file) => {
        setFile(file);
    }

    /**
     * Handle remove logo
     */
    const onRemoveLogo = () => {
        setFile(null);
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            sorter: false,
            valueType: 'text'
        },
        {
            title: "Logo",
            dataIndex: 'logo',
            hideInSearch: true,
            render: (_, record) => [
                record?.logo ? (<img key="icon" src={window.base_url + record.logo} style={{height: "auto", width: "60px"}} />) : ''
            ]
        },
        {
            title: "Category",
            dataIndex: 'category_name',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title:'Sort',
            dataIndex: 'sort',
            hideInSearch: true
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'red',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" key="status" unCheckedChildren="Deactive" onChange={(value) => handledChangeStatus(value, record)} defaultChecked={record?.status  == 1 ? true : false} />
            ]
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="edit"
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        form.setFieldsValue(record);
                        handleModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><DeleteOutlined />  Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Partner Manager"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            form.resetFields();
                            setCurrentRow(null);
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
            />

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new and Edit*/}
            <ModalForm
                id="form-add-partner"
                title="Partner Form"
                width="640px"
                form={form}
                submitter={false}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    value={currentRow?.name}
                    width="full"
                    name="name"
                    label="Name:"
                />

                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Credit Price is required"
                        },
                    ]}
                    value={currentRow?.sort}
                    label="Sort:"
                    placeholder="Sort"
                    name="sort"
                    min={0}
                />

                <ProFormSelect
                    name="status"
                    label="Status"
                    rules={[
                        {
                            required: true,
                            message: "Status is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Active', value: 1},
                        { label: 'Deactive', value: 0 }
                    ]}
                    value={currentRow?.status}
                    placeholder="Status"
                />

                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Sort is required"
                        },
                    ]}
                    value={currentRow?.sort}
                    label="Sort" 
                    name="sort" 
                    width="100%" 
                    min={0}
                />

                <div className="form-group">
                    <Upload accept="image/*" customRequest={false} beforeUpload={onChangeLogo} onRemove={onRemoveLogo}>
                        <Button htmlType='button'><UploadOutlined /> Upload Logo</Button>
                    </Upload>
                    {
                        currentRow?.logo ? (
                            <img src={window.base_url + currentRow?.logo} alt="avatar" style={{ width: '100%', marginTop: '10px', height: "40px", width: 'auto' }} />
                        ) : ''
                    }
                </div>

                <ProFormText
                    value={currentRow?.url}
                    width="full"
                    name="url"
                    label="Url:"
                />

                <ProFormSelect
                    width="lg"
                    name="category_id"
                    label="Category"
                    request={categories}
                    value={currentRow?.category_id}
                    placeholder="Please select a Category"
                    rules={[
                        { 
                            required: true,
                            message: 'Please select a Category!'
                        }
                    ]}
                />

                <ProFormTextArea
                    row="10"
                    name="description"
                    label="Description:"
                    placeholder="Description:"
                    value={currentRow?.description}
                />

                <ProFormSelect
                    name="senior_partner"
                    label="Senior Partner"
                    rules={[
                        {
                            required: true,
                            message: "Senior Partner is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Yes', value: 1},
                        { label: 'No', value: 0 }
                    ]}
                    value={currentRow?.senior_partner}
                    placeholder="Senior Partner"
                />

                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm"
                        loading={loadingButton}
                        onClick={async () => {
                            const value = form.getFieldsValue();
                            form.submit();
                            if (value.name && value.status != null) {
                                setLoadingButton(true);
                                if (currentRow?.id) {
                                    const success = await handleUpdate({...value}, file, currentRow?.id);
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        onRemoveLogo();
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                } else {
                                    const success = await handleAdd({...value}, file);
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        onRemoveLogo();
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                }
                                setLoadingButton(false);
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default PartnerComponent;
