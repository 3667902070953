import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Form, notification, Button, Select, message } from 'antd';
import { account } from '../../../endpoint';

const ProxyItem = ({item, profile, isShowTrialProxy}) => {
    const history = useHistory();
    const [packageIdTrialProxy, setPackageIdTrialProxy] = useState(null);
    const [isModalVisibleTrialProxy, setIsModalVisibleTrialProxy] = useState(false);
    const [proxyTypeTrial, setProxyTypeTrial] = useState('proxy_https');
    const user = JSON.parse(localStorage.getItem('user'));

    /**
     * Handle nl2br textarea
     */
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    /**
     * Handle calculater discount
     */
    const handledCalculaterDiscount = (item) => {
        if (item?.discount) {
            return item?.plan_price - parseInt((item?.plan_price * item?.discount.discount)/100);
        }
        return item?.plan_price;
    }

    /**
     * Show button trial proxy
     *
     * @param packageId
     */
    function getButtonTrialProxy(packageId) {
        return (
            isShowTrialProxy && (
                <a className="btn-continue-google d-block mr-0 ml-0 mb-2" style={{minWidth: "150px"}} onClick={() => {
                    if (user != null) {
                        setPackageIdTrialProxy(packageId);
                        setIsModalVisibleTrialProxy(true);
                    } else {
                        history.push({pathname: '/login.html'});
                        return;
                    }
                }}>Free Trial</a>
            )
        )
    }

    /**
     * Handle check block buy proxy
     *
     * @param packageId
     */
    function checkBlockBuyProxy(packageId) {
        if (profile?.is_buy_proxy == 0) {
            notification.open({
                message: 'Fail!',
                description: "Your account is temporarily locked for 3 days due to violating the rules.",
                type: 'error'
            });
            return ;
        } else if (profile?.is_buy_proxy == 2) {
            notification.open({
                message: 'Fail!',
                description: "Your account has been permanently locked due to repeated violations of the rules.",
                type: 'error'
            });
            return;
        }
        location.href = `/buy-proxy/` + packageId;
        return;
    }

    /**
     * Handle register trial proxy
     */
    const handleTrialProxy = () => {
        const hide = message.loading('Processing...');
        setIsModalVisibleTrialProxy(false);
        account.check_trial_proxy({'id': packageIdTrialProxy, 'type': proxyTypeTrial}).then(res => {
            if (res.status == 'success') {
                location.href = window.base_url + 'proxy-google-redirect/' + res.trial_id;
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Trial registration failed.',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(complete => {
            hide();
        }).then(err => {
            hide();
        });
    }

    useEffect(() => {
    }, [item, profile, isShowTrialProxy]);

    return (
        <>
            <div className="package-s5 package-proxy">
                <div className="package-side package-side-left">
                    <div className="package-header">
                        <h3 className="package-title">
                            {
                                item?.icon ? (<img src={window.base_url + item.icon} style={{width: "30px", height: "auto", marginRight: "10px"}} />) : ''
                            }
                            <b>{item.plan_name}</b>
                        </h3>
                        <div className="package-price">
                            <div className="price">
                                <div className="price-amount">
                                    <span className='current-price'>{handledCalculaterDiscount(item)} Point</span>
                                    {
                                        item?.discount ? (
                                            <span className='discount-price'>{item?.plan_price} Point</span>
                                        ) : ""
                                    }
                                </div>
                                <div className="price-cycle">30 days</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="package-body">
                    <div className="package-content">
                        <div className='text-left' dangerouslySetInnerHTML={{ __html: nl2br(item.description) }}></div>
                    </div>
                </div>
                <div className="package-footer package-side package-side-right">
                    <a onClick={() => {checkBlockBuyProxy(item.id)}} className='btn btn-primary mb-3 d-block'><i className="fa fa-shopping-cart"></i> Buy Proxy Now</a>
                    { item.is_trial == 1 ? getButtonTrialProxy(item.id) : '' }
                    {
                        item?.discount ? (
                            <p className="actlabel_coner d-block text-danger mb-0">SALE OFF {item?.discount.discount}%</p>
                        ) : ""
                    }
                </div>
            </div>
            <Modal visible={isModalVisibleTrialProxy} footer={null} onCancel={() => {setIsModalVisibleTrialProxy(false)}} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className='mb-1 text-center'>We offer a package free trial. (<span className='text-danger'>Time trial: 60 minutes</span>)</p>
                        <p className='mb-1 text-center'>You plz connect account google.</p>
                        <p className='mb-1 text-center'>My site will activate your account package trial</p>
                        <p className='mb-1 text-center text-danger'>We only support personal emails. @gmail email address</p>
                        <p className='text-center'>Thank you very much</p>
                        <Form.Item
                            label="Proxy Type:"
                            labelCol={{span: 24}}
                            name="type"
                        >
                            <Select style={{width: "100%"}} defaultValue="proxy_https" onChange={(val) => { setProxyTypeTrial(val) }}>
                                <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                <Option value="proxy_https">HTTPS Proxy</Option>
                            </Select>
                        </Form.Item>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleTrialProxy} className="d-block medium">Continue</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={() => {setIsModalVisibleTrialProxy(false)}} className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ProxyItem;
