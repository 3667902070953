import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Select ,Pagination, Spin, notification, message } from 'antd';
import { account, authenticator } from '../../endpoint';
import { getParamsByUrlPaging } from '../../_until';
import icBuyPoint from '../../assets/images/ic-buy-point.png';

const CreditHistory = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [dataLists, setDataLists] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [current, setCurrent] = useState(1);
    const [dataForm, setDataForm] = useState({});
    const [dataService, setDataService] = useState({});
    const [profile, setProfile] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle change page
     *
     * @param page
     * @param pageSize
     */
    const onChange = (page, pageSize) =>{
        setCurrent(page);
        setPageSize(pageSize);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    /**
     * Load list credit history
     *
     * @param values
     */
    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);

        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        account.get_list_credit_history(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setFirst(false);
                setDataService(res?.data_service);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    /**
     * Handle get info profile
     */
    const getProfile = () => {
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
            }
        });
    }

    /**
     * Handle get type
     *
     * @param objectType
     */
    const getType = (objectType) => {
        if (objectType == 'renew_proxy') {
            return 'Renew Proxy';
        } else if (objectType == 'changeip_proxy') {
            return 'Change IP Proxy';
        } else if (objectType == 'create_proxy') {
            return 'Buy Proxy';
        } else if (objectType == 'renew_vps') {
            return 'Renew Cloud VPS';
        } else if (objectType == 'changeip_vps') {
            return 'Change IP Cloud VPS';
        } else if (objectType == 'create_vps') {
            return 'Buy Cloud VPS';
        } else if (objectType == 'buy_credit') {
            return 'Buy Point';
        } else if (objectType == 'balance_credit') {
            return 'Buy Point';
        } else if (objectType == 'ref_credit') {
            return 'Referral';
        }
        return 'Other';
    }

    /**
     * Handle set method
     *
     * @param method
     */
    const setMethod = (method) => {
        dataForm['method'] = method;
        loadList({current: current, pageSize: pageSize, ...dataForm});
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "SS5 Wallet - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadList({all: 1, ...paramQuery});
                    if(paramQuery?.current){
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                    getProfile();
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            <div className="content mb-2">
                {
                    loading && first ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className='row no-gutters top-service'>
                            <div className='col-md-3 col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">SS5 Wallet</div>
                                    <div className="panel-body bg-primary">
                                        <h3 className='text-white'>{profile?.credit} Point</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Buy Now</div>
                                    <div className="panel-body">
                                        <div>
                                            <Link to='/buy-point.html' className='btn btn-primary mb-3'><img src={icBuyPoint} width="18px" /> Buy Point Now</Link><br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Total deposit</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.total_deposit} Point</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Total used</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.total_using} Point</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="content">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">SS5 Wallet</div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <Form.Item name="method">
                                            <Select style={{width: "100%"}} defaultValue={paramQuery?.method} placeholder="Method" onChange={setMethod}>
                                                <Option value="+">Plus</Option>
                                                <Option value="-">Min</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Price</th>
                                                <th>Method</th>
                                                <th>Type</th>
                                                <th>Account balance</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((current - 1)*20) + key + 1}</td>
                                                        <td data-title="Price">{item.credit} Point</td>
                                                        <td data-title="Type">{item.type == '+' ? 'Plus' : 'Min'}</td>
                                                        <td data-title="Type">{getType(item.object_type)}</td>
                                                        <td data-title="Account balance">{item.balance} Point</td>
                                                        <td data-title="Status"><span className='text-success text-bold'>Complete</span></td>
                                                        <td data-title="Date">{item.date}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="7" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    total > 20 && !loading ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize={pageSize} onChange={onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default CreditHistory;