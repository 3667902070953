import React, { useState, useEffect } from 'react';
import { Tabs, Spin, Modal, Form, Select, message } from 'antd';
import { useHistory } from "react-router-dom";
import { plan, config, banner, account, authenticator, vps } from '../../endpoint';
import { getParamsByUrlPaging } from '../../_until';

import PremiumList from './include/PremiumList';
import DailyList from './include/DailyList';
import Socks5List from './include/Socks5List';
import ProxyItem from './include/ProxyItem';
import VpsItem from './include/VpsItem';
import S5Item from './include/S5Item';

import s5ABC from '../../assets/images/abc.png';
import s5922 from '../../assets/images/922.png';
import s5PIA from '../../assets/images/pia.png';

const { TabPane } = Tabs;
const Buysock = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [dataListPackageProxy, setListPackageProxy] = useState([]);
    const [dataListPackageVps, setListPackageVps] = useState([]);
    const [dataListBanner, setListBanner] = useState([]);
    const [profile, setProfile] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [data, setData] = useState([]);
    const [note, setNote] = useState("");
    const [isShowTrial, setIsShowTrial] = useState(false);
    const [isShowTrialProxy, setIsShowTrialProxy] = useState(false);
    const [isShowTrialVps, setIsShowTrialVps] = useState(false);
    const [settingWebsite, setSettingWebsite] = useState({});
    const [dataOs, setDataOs] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle close modal
     */
    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalVisibleAds(false);
        setIsModalVisiblePlan(false);
    };

    /**
     * Handle redirect ads
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    /**
     * Load list Operating system
     */
    const loadListOs = () => {
        vps.getListOsFrontend().then(res => {
            if (res.status == 'success') {
                setDataOs(res.list_os);
            }
        }).then(res => {
        });
    }

    /**
     * Handle nl2br textarea
     */
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Buy Socks5 - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                        }
                    });
        
                    account.note().then(res => {
                        if (res.status == 'success' && res.response) {
                            setNote(nl2br(res.response.config_description));
                        }
                    });

                    account.check_data_trial(user.user_id).then(res => {
                        if (res.status == 'success') {
                            if (res.is_sock == 1) {
                                setIsShowTrial(true);
                            }
                            if (res.is_proxy == 1) {
                                setIsShowTrialProxy(true);
                            }
                            if (res.is_vps == 1) {
                                setIsShowTrialVps(true);
                            }
                        }
                    });
                }
            }).then(res => {
            });
        }

        plan.getListPlan().then(response => {
            if (response.status == 'success') {
                setData(response.response);
                setLoading(false);
            }
        });

        plan.getListPackageProxy().then(res => {
            if (res.status == 'success') {
                setListPackageProxy(res.response.proxy);
            }
        });

        plan.getListPackageVps({country: 'us'}).then(res => {
            if (res.status == 'success') {
                setListPackageVps(res.response.vps);
            }
        });

        banner.get_list_banner({position: 'buy_sock'}).then(res => {
            if (res.status == 'success') {
                setListBanner(res.response);
            }
        });

        config.getSettingWebsite().then(res => {
            if (res.status == 'success') {
                setSettingWebsite(res.response);
            }
        });

        loadListOs();
    }, []);

    /**
     * Show list banner
     */
    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full == 1 ? '6' : '6')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                            <div className={"tab-buy-socks nav-pills" + (loading ? " d-none" : "")}>
                                <Tabs defaultActiveKey={paramQuery?.tab ?? 'socks5_premium'}>
                                    <TabPane tab="ISP SOCKS5" key="socks5_premium">
                                        <div title="PLAN ISP SOCKS5" className="plan panel panel-default">
                                            <h3 className="panel-heading mb-2">PLAN ISP SOCKS5</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_premium }}></div>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12 pt-3">
                                                    <PremiumList listData={data?.Premium} profile={profile} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="SOCKS5 DAILY" key="socks5_daily">
                                        <div title="PLAN SOCKS5 DAILY" className="plan panel panel-default mt-3">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 DAILY</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_daily }}></div>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12 pt-3">
                                                    <DailyList listData={data?.Daily} profile={profile} isShowTrial={isShowTrial} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="PLAN SOCKS5 LIST" key="socks5_list">
                                        <div title="PLAN SOCKS5 LIST" className="plan panel panel-default mt-3">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 LIST</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_list }}></div>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12 pt-3">
                                                    <Socks5List listData={data?.List} profile={profile} isShowTrial={isShowTrial} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Static Datacenter Proxies" key="static_proxies">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                                                <div className={"" + (loading ? " d-none" : "")}>
                                                    <div className="plan panel panel-default">
                                                        <h3 className="panel-heading mb-2">Static Datacenter Proxies</h3>
                                                        <div className="panel-body">
                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_package_proxy }}></div>
                                                        </div>
                                                    </div>
                                                    {
                                                        note != '' ? (
                                                            <div className="plan panel panel-default">
                                                                <div className="panel-body">
                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    }
                                                    <div className='list-package plan-table pl-0 pr-0'>
                                                        <div className='row'>
                                                            {
                                                                dataListPackageProxy && dataListPackageProxy.length > 0 && dataListPackageProxy.map((item, key) => 
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <ProxyItem item={item} profile={profile} isShowTrialProxy={isShowTrialProxy} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Cloud VPS" key="cloud_vps">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                                                <div className={"" + (loading ? " d-none" : "")}>
                                                    <div className="plan panel panel-default">
                                                        <h3 className="panel-heading mb-2">Cloud VPS</h3>
                                                        <div className="panel-body">
                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_package_vps }}></div>
                                                        </div>
                                                    </div>
                                                    {
                                                        note != '' ? (
                                                            <div className="plan panel panel-default">
                                                                <div className="panel-body">
                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    }
                                                    <Tabs>
                                                        <TabPane tab="USA (US)" key="US">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'US' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="Singapore (SG)" key="SG">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'SG' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="Australia (AU)" key="AU">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'AU' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="Canada (CA)" key="CA">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'CA' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="UK (UK)" key="UK">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'UK' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="Germany (DE)" key="DE">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'DE' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="France (FR)" key="FR">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'FR' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="Japanese (JP)" key="JP">
                                                            <div className='row'>
                                                                {
                                                                    dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                        item.country_code == 'JP' && (
                                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    {/* <TabPane tab="Reseller S5Proxy" key="s5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Tabs defaultActiveKey="922-s5proxy">
                                                    <TabPane tab="922 S5Proxy" key="922-s5proxy">
                                                        <div title="922 S5Proxy" className="plan panel panel-default mt-3">
                                                            <h3 className="panel-heading mb-2">922 S5Proxy</h3>
                                                            <div className="panel-body">
                                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_922 }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="plan-table mt-3">
                                                            <div className="row">
                                                                {
                                                                    data?.s5922 && data?.s5922.length > 0 && data?.s5922.map((item, key) => (
                                                                        <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                            <S5Item item={item} image={s5922} />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tab="Abc S5Proxy" key="abc-s5proxy" disabled={true}>
                                                        <div title="ABC S5Proxy" className="plan panel panel-default mt-3">
                                                            <h3 className="panel-heading mb-2">ABC S5Proxy</h3>
                                                            <div className="panel-body">
                                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_abc }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="plan-table mt-3">
                                                            <div className="row">
                                                            {
                                                                    data?.s5abc && data?.s5abc.length > 0 && data?.s5abc.map((item, key) => (
                                                                        <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                            <S5Item item={item} image={s5ABC} />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tab="PIA S5Proxy" key="pia-s5proxy" disabled={true}>
                                                        <div title="PIA S5Proxy" className="plan panel panel-default mt-3">
                                                            <h3 className="panel-heading mb-2">PIA S5Proxy</h3>
                                                            <div className="panel-body">
                                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_pia }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="plan-table mt-3">
                                                            <div className="row">
                                                            {
                                                                    data?.s5pia && data?.s5pia.length > 0 && data?.s5pia.map((item, key) => (
                                                                        <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                            <S5Item item={item} image={s5PIA} />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </TabPane> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Buysock;