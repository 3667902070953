export const constants = {
    Bitcoin: 'BTC',
    BitcoinCash: 'BCH',
    Litecoin: 'LTC',
    USDT: 'USDT',
    TRC20: 'TRC-20',
    ERC20: 'ERC-20',
    BSC: 'BEP-20',
    Credit: 'credit',
    PerfectMoney: 'perfect-money',
    WebMoney: 'webMoney',
    Payeer: 'payeer',
    Cryptomus: 'cryptomus',
    Point: 'point'
}
