import React, { useState, useEffect } from 'react';
import { Spin, Modal } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { plan, config, banner, account, authenticator } from '../../endpoint';

const BuyCreditComponent = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [dataListBanner, setListBanner] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [data, setData] = useState([]);
    const [note, setNote] = useState("");
    const [settingWebsite, setSettingWebsite] = useState({});
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle close modal
     */
    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    /**
     * Handle redirect ads
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    /**
     * Handle nl2br textarea
     */
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Buy Credit - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.note().then(res => {
                        if (res.status == 'success' && res.response) {
                            setNote(nl2br(res.response.config_description));
                        }
                    });
                }
            }).then(res => {
            });
        }

        plan.getListPlan().then(response => {
            if (response.status == 'success') {
                setData(response.response);
                setLoading(false);
            }
        });

        banner.get_list_banner({position: 'buy_sock'}).then(res => {
            if (res.status == 'success') {
                setListBanner(res.response);
            }
        });

        config.getSettingWebsite().then(res => {
            if (res.status == 'success') {
                setSettingWebsite(res.response);
            }
        });
    }, []);

    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full == 1 ? '6' : '6')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                            <div className={"tab-buy-socks nav-pills" + (loading ? " d-none" : "")}>
                                <div title="Buy Point" className="plan panel panel-default">
                                    <h3 className="panel-heading mb-2">Buy Point</h3>
                                    <div className="panel-body">
                                        <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_credit }}></div>
                                    </div>
                                </div>
                                {
                                    note != '' ? (
                                        <div className="plan panel panel-default">
                                            <div className="panel-body">
                                                <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                <div className="plan-table remove-padding tab-children">
                                    <div className="row">
                                        <div className="col-md-12 pt-3">
                                            <div className="plan-package-box ips-box residential__plan_box credit-package-box ">
                                                <div className="plan-package-setting">
                                                    <div className="setting-top">
                                                        <div className="setting-box residential__setting">
                                                            <div className="set-tit">Select the plan that your requirements</div>
                                                            <div className="set-change-plan d-none">
                                                                <span className="ips-popular setting-tit residential__setting_tit residential__popular setting__onswitch">Popular Plan</span>
                                                                <div className="layui-form"></div>
                                                                <span className="ips-enterprise setting-tit residential__setting_tit residential__enterprise">Enterprise Plan</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="setting-adv">
                                                        <div className="setting-item">Point</div>
                                                        <div className="setting-item">Buy Public Socks5</div>
                                                        <div className="setting-item">Buy Static Proxies</div>
                                                        <div className="setting-item">Buy Cloud VPS</div>
                                                        <div className="setting-item setting-item-btn"></div>
                                                    </div>
                                                </div>
                                                <div className="plan-package-content residential__package residential__package_popular">
                                                    {
                                                        data?.buy_credit && data?.buy_credit.length > 0 && data?.buy_credit.map((item, key) => 
                                                            <div className="plan-package-item plan__package_item" key={item.plan_id}>
                                                                <div className="package-item-content">
                                                                    <div className="package-detail">
                                                                        <div className="package-num">
                                                                            <span className="package-number">
                                                                                <span className="residential__num">{item.plan_name}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="package-price">
                                                                            <span>$</span>
                                                                            <span className="residential__total">{item.plan_price}</span>
                                                                            <span className="package-original-price d-none">
                                                                                <span>$</span>
                                                                                <span className="residential__original"></span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="package-adv">
                                                                        <div className="adv-item">
                                                                            <span className="adv-item-detail"></span>
                                                                        </div>
                                                                        <div className="adv-item">
                                                                            <span className="adv-item-tit">Point</span>
                                                                            <span className="adv-item-detail">
                                                                                <span className="residential__all_flow">{item?.plan_daily_limit}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="adv-item">
                                                                            <span className="adv-item-tit">Buy Public Socks5</span>
                                                                            <span className="adv-item-detail">
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="adv-item">
                                                                            <span className="adv-item-tit">Buy Static Proxies</span>
                                                                            <span className="adv-item-detail">
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="adv-item">
                                                                            <span className="adv-item-tit">Buy Cloud VPS</span>
                                                                            <span className="adv-item-detail">
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="package-btn">
                                                                        <Link to={"/payment/" + item.plan_id} className="btn residential__pay_btn">Buy Now</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default BuyCreditComponent;
