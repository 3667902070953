import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Spin, notification, message, Button, Tooltip } from 'antd';
import { account, authenticator, plan, payment } from '../../endpoint';
import { getParamsByUrl, constants } from '../../_until';

import icPaymentPoint from '../../assets/theme/images/ic-payment-point.png';
import icPaymentPayeer from '../../assets/theme/images/ic-payment-payeer.png';

const Payment = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [method, setMethod] = useState('');
    const [network, setNetwork] = useState('');
    const [packagePlan, setPackage] = useState({});
    const [fee, setFee] = useState(0);
    const [profile, setProfile] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const { id } = useParams();

    /**
     * Get Coin Name
     *
     * @param type
     */
    const getCoinName = (type) => {
        if (type == constants.Bitcoin.toLowerCase()) {
            return 'Bitcoin (BTC)';
        } else if (type == constants.BitcoinCash.toLowerCase()) {
            return 'Bitcoin Cash (BCH)';
        } else if (type == constants.ERC20.toLowerCase()) {
            return 'USDT (ERC20)';
        } else if (type == constants.TRC20.toLowerCase()) {
            return 'USDT (TRC20)';
        } else if (type == constants.BSC.toLowerCase()) {
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else if (type == constants.Litecoin.toLowerCase()) {
            return 'Litecoin (LTC)';
        } else if (type == constants.Point.toLowerCase()) {
            return 'Point';
        } else if (type == constants.Payeer.toLowerCase()) {
            return 'Payeer';
        }
        return '';
    }

    /**
     * Handle change payment method
     *
     * @param methodType
     * @param network
     */
    const onChangeMethod = (methodType, network) => {
        if (methodType != method) {
            setMethod(methodType);
            setNetwork(network);
            onChangeFee(methodType);
        }
    }

    /**
     * Handle change network
     *
     * @param network
     */
    const onChangeNetwork = (network) => {
        setNetwork(network);
    }

    /**
     * Handle change fee
     *
     * @param methodType
     */
    const onChangeFee = (methodType) => {
        if (methodType == constants.Payeer.toLowerCase()) {
            setFee(1);
        } else if (methodType == constants.Cryptomus.toLowerCase()) {
            setFee(5);
        } else {
            setFee(0);
        }
    }

    /**
     * Handle calculator fee
     */
    const calculatorFee = () => {
        let planPrice = parseFloat(packagePlan?.plan_price);
        if (packagePlan?.discount) {
            return (planPrice - planPrice * packagePlan?.discount.discount / 100) * fee / 100;
        }
        return planPrice * fee / 100;
    }

    /**
     * Handle calculator discount
     */
    const calculatorDiscount = () => {
        if (packagePlan?.discount) {
            return (parseFloat(packagePlan?.plan_price) * packagePlan?.discount.discount / 100);
        }
        return 0;
    }

    /**
     * Handle calculator total
     */
    const calculatorTotal = () => {
        if (method == constants.Point.toLowerCase()) {
            return ((parseFloat(packagePlan?.plan_price) - calculatorDiscount()) * 10) + ' Point';
        }
        return (parseFloat(packagePlan?.plan_price) - calculatorDiscount() + calculatorFee()) + '$';
    }

    /**
     * Handle continue payment
     */
    const handlePayment = () => {
        const hide = message.loading('Processing...');
        setLoadingButton(true);
        if (method == constants.Point.toLowerCase()) {
            payment.payment_point({id: id, 'type_product': paramQuery?.type_product}).then(res => {
                if (res.status == 'success') {
                    window.location.href = "/success.html";
                } else if (res.status == 'fail') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(complete => {
                hide();
                setLoadingButton(false);
            }).then(err => {
                hide();
                setLoadingButton(false);
            });
        } else {
            payment.get_confirm({plan_id: id, 'type': method, 'type_product': paramQuery?.type_product, 'network': network}).then(res => {
                if (res.status == 'success') {
                    const response = res.response;
                    if (response.plan_price == packagePlan.plan_price) {
                        if (method == constants.PerfectMoney.toLowerCase()) {
                            $('#div_element').html($.base64.atob(res.code, true));
                        } else if (method == constants.WebMoney.toLowerCase()) {
                            $('#div_element').html($.base64.atob(res.code, true));
                        } else if (method == constants.Payeer.toLowerCase()) {
                            location.href = res.data_payment.payeer_url;
                        } else if (method == constants.Cryptomus.toLowerCase()) {
                            location.href = '/crypto-payment/' + res.code;
                        }
                    }
                } else if (res.status == 'fail') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(complete => {
                hide();
                setLoadingButton(false);
            }).then(err => {
                hide();
                setLoadingButton(false);
            });
        }
    }

    /**
     * Handle get info profile
     */
    const getProfile = () => {
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Payment - " + window?.title;
        if (user != null) {
            getProfile();
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    plan.getPlanItem(id, paramQuery?.type_product).then(res => {
                        if (res.status == 'success') {
                            setPackage(res.response);
                            setLoading(false);
                            if (paramQuery?.type_product == 's5') {
                                setMethod(constants.Point.toLowerCase());
                                onChangeFee(constants.Point.toLowerCase());
                            }
                        } else {
                            history.push({pathname: '/home'});
                        }
                    }).then(complete => {
                    }).then(err => {
                    });
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    if (id == null) {
        history.push({pathname: '/home'});
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-12 col-md-8 order-2 order-md-1'>
                    {
                        loading ? (
                            <div className='text-center'><Spin /></div>
                        ) : (
                            <div className='payment-choose'>
                                {
                                    packagePlan?.plan_type != 'buy_credit' && (
                                        <div className={"pay_n_detail zfb_pay_type payment-type-point" + (method == constants.Point.toLowerCase() ? " active" : "")}>
                                            <div className="pi_top" onClick={() => onChangeMethod(constants.Point.toLowerCase(), constants.Point.toLowerCase())}>
                                                <p className="paytype_gift">
                                                    <span>SS5 Point</span>
                                                </p>
                                                <p className='paytype_description'>Remaining Point: {profile?.credit} Point</p>
                                                <div className="pay_type_logo">
                                                    <Tooltip className="logo_detail" placement="top" title="SS5 Point">
                                                        <img src={icPaymentPoint} />
                                                    </Tooltip>
                                                </div>
                                                <i></i>
                                            </div>
                                            <div className="pay_type_one pay_type_three">
                                                <div className="cun_telegram_detail pay_virtual_currency">
                                                    <div className="bind_taxtid">
                                                        <p className='mb-2'>Click to continue to pay to complete the payment</p>
                                                        <Button type="primary" loading={loadingButton} onClick={handlePayment} className="pay_btn">Continue to Pay</Button>
                                                        <div className="pay_btn_tips mt-2">
                                                            <p>
                                                                By clicking continue to pay, you agree to our &nbsp;
                                                                <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target="_blank">Terms of Service</a> 
                                                                &nbsp; and &nbsp;
                                                                <a href="https://shopsocks5.com/faq-detail/Refund_Policy_and_Dispute_Resolution" target="_blank">Privacy Policy</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    paramQuery?.type_product != 's5' && (
                                        <div className={"pay_n_detail zfb_pay_type" + (window.confirm.is_payment_payeer != "1" ? ' d-none' : '') + (method == constants.Payeer.toLowerCase() ? " active" : "")}>
                                            <div className="pi_top" onClick={() => onChangeMethod(constants.Payeer.toLowerCase(), constants.Payeer.toLowerCase())}>
                                                <p className="paytype_gift">
                                                    <span>Payeer</span>
                                                </p>
                                                <div className="pay_type_logo">
                                                    <Tooltip className="logo_detail" placement="top" title="Payeer">
                                                        <img src={icPaymentPayeer} />
                                                    </Tooltip>
                                                </div>
                                                <i></i>
                                            </div>
                                            <div className="pay_type_one pay_type_three">
                                                <div className="cun_telegram_detail pay_virtual_currency">
                                                    <div className="bind_taxtid">
                                                        <p className='mb-2'>Click to continue to pay to complete the payment</p>
                                                        <Button type="primary" loading={loadingButton} onClick={handlePayment} className="pay_btn">Continue to Pay</Button>
                                                        <div className="pay_btn_tips mt-2">
                                                            <p>
                                                                By clicking continue to pay, you agree to our &nbsp;
                                                                <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target="_blank">Terms of Service</a> 
                                                                &nbsp; and &nbsp;
                                                                <a href="https://shopsocks5.com/faq-detail/Refund_Policy_and_Dispute_Resolution" target="_blank">Privacy Policy</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    window.isCryptomus && paramQuery?.type_product != 's5' && (
                                        <div className={"pay_n_detail virtual_currency" + (method == constants.Cryptomus.toLowerCase() ? " active" : "")}>
                                            <div className="pi_top" onClick={() => onChangeMethod(constants.Cryptomus.toLowerCase(), constants.ERC20.toLowerCase())}>
                                                <p className="paytype_gift">
                                                    <span>Crypto Currency</span>
                                                </p>
                                                <div className="pay_type_logo">
                                                    <Tooltip className={"logo_detail" + (window.confirm.is_payment_btc != "1" ? ' d-none' : '')} placement="top" title="Bitcoin(BTC)">
                                                        <span className="credit_tis_invit">Bitcoin(BTC)</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-214.000000, -339.000000)">
                                                                    <g transform="translate(214.000000, 339.000000)">
                                                                        <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                        <g transform="translate(2.000000, 2.000000)">
                                                                            <path d="M11,0 L11,0 C17.07475,0 22,4.92525 22,11 C22,17.07475 17.07475,22 11,22 C4.92525,22 0,17.07475 0,11 C0,4.92525 4.92525,0 11,0" id="Fill-1" fill="#F9934A"/>
                                                                            <path d="M10.0010412,10.8737501 L12.4475024,11.4915433 C13.1640437,11.6846902 13.5883935,12.4220882 13.3952466,13.1386295 C13.3888216,13.1622539 13.3818035,13.1857795 13.3741923,13.2090085 C13.1548509,14.0925023 12.2868762,14.287033 11.8574851,14.287033 C11.7942231,14.2890099 11.7308622,14.2827826 11.6690829,14.2685486 L9.30604851,13.6815956 L10.0010412,10.8737501 Z M10.9925746,6.8179622 L12.5587052,7.21018677 C12.82747,7.27819345 14.0784765,7.82807885 13.7942917,8.93694357 C13.6509636,9.56976154 13.0668772,10.0029087 12.4197264,9.95635184 C12.2920162,9.9560553 12.1647014,9.94261212 12.0397589,9.91612114 L10.3130021,9.48366588 L10.9925746,6.8179622 Z M6.92749513,13.1410018 C6.9059465,13.230656 6.89359063,13.2831437 6.76993314,13.2522046 L5.36136456,12.9001119 L4.99998023,14.3519754 L7.59787492,15.0006089 L7.07270125,17.1103975 L8.45962235,17.4563617 L8.98479603,15.3465731 L9.8466423,15.5628502 L9.32146863,17.6694757 L10.6404819,18.0000198 L11.1687198,15.8902312 L12.1602533,16.1404127 C12.8212426,16.304202 15.3264189,16.3350423 15.8670127,14.14499 C16.2236524,12.9392553 15.7028279,11.6466341 14.6097787,11.0250847 C14.6097787,11.0250847 16.1542618,10.5524976 16.3705389,9.13780051 C16.5867171,7.72300458 15.6013122,7.06507949 15.0298781,6.70359631 C14.5803223,6.44402431 14.1025952,6.23674232 13.6058894,6.08580308 L14.155676,3.88033065 L12.8860862,3.57143403 L12.3424281,5.7645506 L11.3231187,5.51130479 L11.8791326,3.30889662 L10.5941227,3 L10.0442373,5.20240817 L7.35688614,4.53212723 L7.02950515,5.82949302 L8.5029173,6.19700586 C8.65118768,6.23407346 8.63260446,6.30820864 8.62024859,6.3576321 L6.92749513,13.1410018 Z" id="Fill-1" fill="#FFFFFF"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                    <Tooltip className={"logo_detail" + (window.confirm.is_payment_erc20 != "1" ? ' d-none' : '')} placement="top" title="USDT (ERC20)">
                                                        <span className="credit_tis_invit">USDT (ERC20)</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-314.000000, -339.000000)">
                                                                    <g id="USDT" transform="translate(314.000000, 339.000000)">
                                                                        <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                        <g transform="translate(2.000000, 2.000000)">
                                                                            <circle fill="#26A17B" cx="11" cy="11" r="11"/>
                                                                            <polygon fill="#FFFFFF" points="5.92063404 5.37303338 16.0147414 5.37303338 16.0147414 7.88552154 5.92063404 7.88552154"/>
                                                                            <polygon fill="#FFFFFF" points="9.61879094 7.54285714 12.2738779 7.54285714 12.2738779 11.4420531 9.61879094 11.4420531"/>
                                                                            <polygon fill="#FFFFFF" points="9.61879094 11.9428571 12.2738779 11.9428571 12.2738779 17.6 9.61879094 17.6"/>
                                                                            <path d="M11,9.42857143 C14.6450793,9.42857143 17.6,10.132124 17.6,11 C17.6,11.867876 14.6450793,12.5714286 11,12.5714286 C7.35492065,12.5714286 4.4,11.867876 4.4,11 C4.4,10.132124 7.35492065,9.42857143 11,9.42857143 Z M10.9463344,9.70017381 C7.64840549,9.70017381 5.02373604,10.3007899 5.02373604,10.8215155 C5.02373604,11.3422411 7.70207107,11.9428571 11,11.9428571 C14.2979289,11.9428571 16.9832526,11.3422411 16.9832526,10.8215155 C16.9832526,10.3007899 14.2442634,9.70017381 10.9463344,9.70017381 Z" fill="#FFFFFF"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                    <Tooltip className={"logo_detail" + (window.confirm.is_payment_trc20 != "1" ? ' d-none' : '')} placement="top" title="USDT (TRC20)">
                                                        <span className="credit_tis_invit">USDT (TRC20)</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-247.000000, -339.000000)">
                                                                    <g transform="translate(247.000000, 339.000000)">
                                                                        <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                        <g id="paytype_virtual11" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
                                                                            <path d="M0,11 C0,13.9173692 1.15892615,16.7152615 3.22182385,18.7781846 C5.28472154,20.8411077 8.08261385,22 11,22 C13.9173692,22 16.7152615,20.8411077 18.7781846,18.7781846 C20.8411077,16.7152615 22,13.9173692 22,11 C22,8.08262231 20.8411077,5.28472154 18.7781846,3.22182385 C16.7152615,1.15892615 13.9173692,0 11,0 C8.08261385,0 5.28472154,1.15892615 3.22182385,3.22182385 C1.15892615,5.28472154 0,8.08262231 0,11 L0,11 Z" fill="#D80917"/>
                                                                            <path d="M10.1220308,17.7884385 C10.1013846,17.7884385 10.0799769,17.7872538 10.0593308,17.7840385 C9.97598462,17.7726154 9.89746154,17.7384308 9.83222308,17.6853769 C9.76706923,17.6322385 9.71765385,17.5621769 9.68964615,17.4828923 L5.22031231,4.82295846 C5.16352692,4.66170692 5.20113846,4.48227154 5.31789923,4.35739615 C5.43466,4.23252923 5.61188692,4.18237769 5.77583769,4.22884 L14.7796,6.73978462 C14.8379,6.75601385 14.8934077,6.78403 14.9408769,6.82213231 L16.6337769,8.16671308 C16.8245,8.31788692 16.8638462,8.59193077 16.7232154,8.7901 L10.4961154,17.5945 C10.4538923,17.6544077 10.3977923,17.7033154 10.3327231,17.7370769 C10.2675692,17.7708385 10.1953077,17.7884385 10.1220308,17.7884385 L10.1220308,17.7884385 Z M6.37832462,5.34850462 L10.2589385,16.3423615 L15.7239923,8.61503077 L14.4438462,7.59815692 L6.37832462,5.34850462 L6.37832462,5.34850462 Z" fill="#FFFFFF"/>
                                                                            <path d="M10.1229615,17.7883538 C10.1060385,17.7883538 10.0897923,17.7873385 10.0727846,17.7856462 C9.82139231,17.7583154 9.63946923,17.5322231 9.66646154,17.2807462 L10.3405077,11.0398538 C10.3673308,10.7873615 10.6010385,10.6101769 10.8454077,10.6335308 C11.0968846,10.6607769 11.2788077,10.8869538 11.2517308,11.1384308 L10.5776846,17.3793231 C10.5526385,17.6140462 10.3540462,17.7883538 10.1229615,17.7883538 L10.1229615,17.7883538 Z" fill="#FFFFFF"/>
                                                                            <path d="M10.7955692,11.5476308 C10.6793077,11.5476308 10.5627923,11.5038846 10.4733538,11.4156308 C10.2934615,11.2374308 10.2916846,10.9473692 10.4696308,10.7672231 L14.3298692,6.85905 C14.5080692,6.67911538 14.7981308,6.67739769 14.9781077,6.85511538 C15.158,7.03333231 15.1596923,7.32338538 14.9817462,7.50356538 L11.1213385,11.4114846 C11.0787769,11.4546385 11.0280923,11.4889077 10.9721615,11.5122615 C10.9162308,11.5356154 10.8562385,11.5476308 10.7955692,11.5476308 L10.7955692,11.5476308 Z" fill="#FFFFFF"/>
                                                                            <path d="M10.7955692,11.5467846 C10.6227,11.5467846 10.4570231,11.4482923 10.3793462,11.2805846 C10.2736615,11.0510231 10.3739308,10.7783923 10.6030692,10.6722 L16.1559538,8.10892077 C16.3869538,8.00273692 16.6580615,8.10302308 16.7645077,8.33310077 C16.8702769,8.56265385 16.7699231,8.83528462 16.5408692,8.94147692 L10.9879846,11.5048154 C10.9275692,11.5324 10.8619923,11.5467846 10.7955692,11.5467846 L10.7955692,11.5467846 Z" fill="#FFFFFF"/>
                                                                            <path d="M10.8621615,11.6270852 C10.7279615,11.6270852 10.5954538,11.5683615 10.505,11.4552308 L5.29507,4.95677769 C5.13652615,4.75939538 5.16847692,4.47106 5.36635846,4.31275308 C5.56325,4.15519077 5.85281231,4.18690462 6.01037462,4.38404154 L11.2193231,10.8825538 C11.3778923,11.0798769 11.3464154,11.3682462 11.1485846,11.5265615 C11.0673538,11.5917154 10.9663231,11.6272538 10.8621615,11.6270852 L10.8621615,11.6270852 Z" fill="#FFFFFF"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                    <Tooltip className={"logo_detail" + (window.confirm.is_payment_bep20 != "1" ? ' d-none' : '')} placement="top" title="USDT BSC (BNB Smart Chain(BEP20))">
                                                        <span className="credit_tis_invit">USDT BSC (BNB Smart Chain(BEP20))</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-314.000000, -339.000000)">
                                                                    <g id="USDT" transform="translate(314.000000, 339.000000)">
                                                                        <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                        <g transform="translate(2.000000, 2.000000)">
                                                                            <circle fill="#26A17B" cx="11" cy="11" r="11"/>
                                                                            <polygon fill="#FFFFFF" points="5.92063404 5.37303338 16.0147414 5.37303338 16.0147414 7.88552154 5.92063404 7.88552154"/>
                                                                            <polygon fill="#FFFFFF" points="9.61879094 7.54285714 12.2738779 7.54285714 12.2738779 11.4420531 9.61879094 11.4420531"/>
                                                                            <polygon fill="#FFFFFF" points="9.61879094 11.9428571 12.2738779 11.9428571 12.2738779 17.6 9.61879094 17.6"/>
                                                                            <path d="M11,9.42857143 C14.6450793,9.42857143 17.6,10.132124 17.6,11 C17.6,11.867876 14.6450793,12.5714286 11,12.5714286 C7.35492065,12.5714286 4.4,11.867876 4.4,11 C4.4,10.132124 7.35492065,9.42857143 11,9.42857143 Z M10.9463344,9.70017381 C7.64840549,9.70017381 5.02373604,10.3007899 5.02373604,10.8215155 C5.02373604,11.3422411 7.70207107,11.9428571 11,11.9428571 C14.2979289,11.9428571 16.9832526,11.3422411 16.9832526,10.8215155 C16.9832526,10.3007899 14.2442634,9.70017381 10.9463344,9.70017381 Z" fill="#FFFFFF"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                    <Tooltip className={"logo_detail" + (window.confirm.is_payment_litecoin != "1" ? ' d-none' : '')} placement="top" title="Litecoin(LTC)">
                                                        <span className="credit_tis_invit">Litecoin(LTC)</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-182.000000, -339.000000)">
                                                                    <g transform="translate(182.000000, 339.000000)">
                                                                        <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                        <g transform="translate(2.000000, 2.000000)">
                                                                            <path d="M11,0 L11,0 C17.07475,0 22,4.92525 22,11 C22,17.07475 17.07475,22 11,22 C4.92525,22 0,17.07475 0,11 C0,4.92525 4.92525,0 11,0" id="Fill-1" fill="#A6A8A9"/>
                                                                            <polygon id="Fill-3" fill="#FFFFFF" points="15.787475 16.1508417 16.4236417 13.779425 10.1958083 13.7922583 10.9978917 10.8195083 12.8376417 10.1585917 13.3335583 8.34175833 11.469975 9.049425 12.7798917 4.08384167 9.04080833 4.08384167 7.31839167 10.559175 5.90214167 11.0550917 5.40714167 12.871925 6.84630833 12.3292583 5.85539167 16.127925"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                    <Tooltip className={"logo_detail" + (window.confirm.is_payment_bch != "1" ? ' d-none' : '')} placement="top" title="Bitcoin Cash(BCH)">
                                                        <span className="credit_tis_invit">Bitcoin Cash(BCH)</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-214.000000, -339.000000)">
                                                                    <g transform="translate(214.000000, 339.000000)">
                                                                        <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                        <g transform="translate(2.000000, 2.000000)">
                                                                            <path d="M11,0 L11,0 C17.07475,0 22,4.92525 22,11 C22,17.07475 17.07475,22 11,22 C4.92525,22 0,17.07475 0,11 C0,4.92525 4.92525,0 11,0" id="Fill-1" fill="#F9934A"/>
                                                                            <path d="M10.0010412,10.8737501 L12.4475024,11.4915433 C13.1640437,11.6846902 13.5883935,12.4220882 13.3952466,13.1386295 C13.3888216,13.1622539 13.3818035,13.1857795 13.3741923,13.2090085 C13.1548509,14.0925023 12.2868762,14.287033 11.8574851,14.287033 C11.7942231,14.2890099 11.7308622,14.2827826 11.6690829,14.2685486 L9.30604851,13.6815956 L10.0010412,10.8737501 Z M10.9925746,6.8179622 L12.5587052,7.21018677 C12.82747,7.27819345 14.0784765,7.82807885 13.7942917,8.93694357 C13.6509636,9.56976154 13.0668772,10.0029087 12.4197264,9.95635184 C12.2920162,9.9560553 12.1647014,9.94261212 12.0397589,9.91612114 L10.3130021,9.48366588 L10.9925746,6.8179622 Z M6.92749513,13.1410018 C6.9059465,13.230656 6.89359063,13.2831437 6.76993314,13.2522046 L5.36136456,12.9001119 L4.99998023,14.3519754 L7.59787492,15.0006089 L7.07270125,17.1103975 L8.45962235,17.4563617 L8.98479603,15.3465731 L9.8466423,15.5628502 L9.32146863,17.6694757 L10.6404819,18.0000198 L11.1687198,15.8902312 L12.1602533,16.1404127 C12.8212426,16.304202 15.3264189,16.3350423 15.8670127,14.14499 C16.2236524,12.9392553 15.7028279,11.6466341 14.6097787,11.0250847 C14.6097787,11.0250847 16.1542618,10.5524976 16.3705389,9.13780051 C16.5867171,7.72300458 15.6013122,7.06507949 15.0298781,6.70359631 C14.5803223,6.44402431 14.1025952,6.23674232 13.6058894,6.08580308 L14.155676,3.88033065 L12.8860862,3.57143403 L12.3424281,5.7645506 L11.3231187,5.51130479 L11.8791326,3.30889662 L10.5941227,3 L10.0442373,5.20240817 L7.35688614,4.53212723 L7.02950515,5.82949302 L8.5029173,6.19700586 C8.65118768,6.23407346 8.63260446,6.30820864 8.62024859,6.3576321 L6.92749513,13.1410018 Z" id="Fill-1" fill="#FFFFFF"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <i></i>
                                            </div>
                                            <div className="pay_type_one pay_type_three">
                                                <div className="cun_telegram_detail pay_virtual_currency">
                                                    <span className="cun_t_d_step">Step1</span>
                                                    <div className="bind_taxtid">
                                                        <p>Choose the type of Crypto Currency you need to pay</p>
                                                        <div className="support_pay_logo">
                                                            <Tooltip className={"s_logo_detail" + (window.confirm.is_payment_erc20 != "1" ? ' d-none' : '') + (network == constants.ERC20.toLowerCase() ? " active" : "")} onClick={() => onChangeNetwork(constants.ERC20.toLowerCase())} placement="top" title="USDT (ERC20)">
                                                                <span>USDT (ERC20)</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g transform="translate(-314.000000, -339.000000)">
                                                                            <g id="USDT" transform="translate(314.000000, 339.000000)">
                                                                                <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                                <g transform="translate(2.000000, 2.000000)">
                                                                                    <circle fill="#26A17B" cx="11" cy="11" r="11"/>
                                                                                    <polygon fill="#FFFFFF" points="5.92063404 5.37303338 16.0147414 5.37303338 16.0147414 7.88552154 5.92063404 7.88552154"/>
                                                                                    <polygon fill="#FFFFFF" points="9.61879094 7.54285714 12.2738779 7.54285714 12.2738779 11.4420531 9.61879094 11.4420531"/>
                                                                                    <polygon fill="#FFFFFF" points="9.61879094 11.9428571 12.2738779 11.9428571 12.2738779 17.6 9.61879094 17.6"/>
                                                                                    <path d="M11,9.42857143 C14.6450793,9.42857143 17.6,10.132124 17.6,11 C17.6,11.867876 14.6450793,12.5714286 11,12.5714286 C7.35492065,12.5714286 4.4,11.867876 4.4,11 C4.4,10.132124 7.35492065,9.42857143 11,9.42857143 Z M10.9463344,9.70017381 C7.64840549,9.70017381 5.02373604,10.3007899 5.02373604,10.8215155 C5.02373604,11.3422411 7.70207107,11.9428571 11,11.9428571 C14.2979289,11.9428571 16.9832526,11.3422411 16.9832526,10.8215155 C16.9832526,10.3007899 14.2442634,9.70017381 10.9463344,9.70017381 Z" fill="#FFFFFF"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </Tooltip>
                                                            <Tooltip className={"s_logo_detail" + (window.confirm.is_payment_trc20 != "1" ? ' d-none' : '') + (network == constants.TRC20.toLowerCase() ? " active" : "")} onClick={() => onChangeNetwork(constants.TRC20.toLowerCase())} placement="top" title="USDT (TRC20)">
                                                                <span>USDT (TRC20)</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g transform="translate(-247.000000, -339.000000)">
                                                                            <g transform="translate(247.000000, 339.000000)">
                                                                                <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                                <g id="paytype_virtual11" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
                                                                                    <path d="M0,11 C0,13.9173692 1.15892615,16.7152615 3.22182385,18.7781846 C5.28472154,20.8411077 8.08261385,22 11,22 C13.9173692,22 16.7152615,20.8411077 18.7781846,18.7781846 C20.8411077,16.7152615 22,13.9173692 22,11 C22,8.08262231 20.8411077,5.28472154 18.7781846,3.22182385 C16.7152615,1.15892615 13.9173692,0 11,0 C8.08261385,0 5.28472154,1.15892615 3.22182385,3.22182385 C1.15892615,5.28472154 0,8.08262231 0,11 L0,11 Z" fill="#D80917"/>
                                                                                    <path d="M10.1220308,17.7884385 C10.1013846,17.7884385 10.0799769,17.7872538 10.0593308,17.7840385 C9.97598462,17.7726154 9.89746154,17.7384308 9.83222308,17.6853769 C9.76706923,17.6322385 9.71765385,17.5621769 9.68964615,17.4828923 L5.22031231,4.82295846 C5.16352692,4.66170692 5.20113846,4.48227154 5.31789923,4.35739615 C5.43466,4.23252923 5.61188692,4.18237769 5.77583769,4.22884 L14.7796,6.73978462 C14.8379,6.75601385 14.8934077,6.78403 14.9408769,6.82213231 L16.6337769,8.16671308 C16.8245,8.31788692 16.8638462,8.59193077 16.7232154,8.7901 L10.4961154,17.5945 C10.4538923,17.6544077 10.3977923,17.7033154 10.3327231,17.7370769 C10.2675692,17.7708385 10.1953077,17.7884385 10.1220308,17.7884385 L10.1220308,17.7884385 Z M6.37832462,5.34850462 L10.2589385,16.3423615 L15.7239923,8.61503077 L14.4438462,7.59815692 L6.37832462,5.34850462 L6.37832462,5.34850462 Z" fill="#FFFFFF"/>
                                                                                    <path d="M10.1229615,17.7883538 C10.1060385,17.7883538 10.0897923,17.7873385 10.0727846,17.7856462 C9.82139231,17.7583154 9.63946923,17.5322231 9.66646154,17.2807462 L10.3405077,11.0398538 C10.3673308,10.7873615 10.6010385,10.6101769 10.8454077,10.6335308 C11.0968846,10.6607769 11.2788077,10.8869538 11.2517308,11.1384308 L10.5776846,17.3793231 C10.5526385,17.6140462 10.3540462,17.7883538 10.1229615,17.7883538 L10.1229615,17.7883538 Z" fill="#FFFFFF"/>
                                                                                    <path d="M10.7955692,11.5476308 C10.6793077,11.5476308 10.5627923,11.5038846 10.4733538,11.4156308 C10.2934615,11.2374308 10.2916846,10.9473692 10.4696308,10.7672231 L14.3298692,6.85905 C14.5080692,6.67911538 14.7981308,6.67739769 14.9781077,6.85511538 C15.158,7.03333231 15.1596923,7.32338538 14.9817462,7.50356538 L11.1213385,11.4114846 C11.0787769,11.4546385 11.0280923,11.4889077 10.9721615,11.5122615 C10.9162308,11.5356154 10.8562385,11.5476308 10.7955692,11.5476308 L10.7955692,11.5476308 Z" fill="#FFFFFF"/>
                                                                                    <path d="M10.7955692,11.5467846 C10.6227,11.5467846 10.4570231,11.4482923 10.3793462,11.2805846 C10.2736615,11.0510231 10.3739308,10.7783923 10.6030692,10.6722 L16.1559538,8.10892077 C16.3869538,8.00273692 16.6580615,8.10302308 16.7645077,8.33310077 C16.8702769,8.56265385 16.7699231,8.83528462 16.5408692,8.94147692 L10.9879846,11.5048154 C10.9275692,11.5324 10.8619923,11.5467846 10.7955692,11.5467846 L10.7955692,11.5467846 Z" fill="#FFFFFF"/>
                                                                                    <path d="M10.8621615,11.6270852 C10.7279615,11.6270852 10.5954538,11.5683615 10.505,11.4552308 L5.29507,4.95677769 C5.13652615,4.75939538 5.16847692,4.47106 5.36635846,4.31275308 C5.56325,4.15519077 5.85281231,4.18690462 6.01037462,4.38404154 L11.2193231,10.8825538 C11.3778923,11.0798769 11.3464154,11.3682462 11.1485846,11.5265615 C11.0673538,11.5917154 10.9663231,11.6272538 10.8621615,11.6270852 L10.8621615,11.6270852 Z" fill="#FFFFFF"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </Tooltip>
                                                            <Tooltip className={"s_logo_detail" + (window.confirm.is_payment_litecoin != "1" ? ' d-none' : '') + (network == constants.Litecoin.toLowerCase() ? " active" : "")} onClick={() => onChangeNetwork(constants.Litecoin.toLowerCase())} placement="top" title="Litecoin(LTC)">
                                                                <span>Litecoin(LTC)</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g transform="translate(-182.000000, -339.000000)">
                                                                            <g transform="translate(182.000000, 339.000000)">
                                                                                <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                                <g transform="translate(2.000000, 2.000000)">
                                                                                    <path d="M11,0 L11,0 C17.07475,0 22,4.92525 22,11 C22,17.07475 17.07475,22 11,22 C4.92525,22 0,17.07475 0,11 C0,4.92525 4.92525,0 11,0" id="Fill-1" fill="#A6A8A9"/>
                                                                                    <polygon id="Fill-3" fill="#FFFFFF" points="15.787475 16.1508417 16.4236417 13.779425 10.1958083 13.7922583 10.9978917 10.8195083 12.8376417 10.1585917 13.3335583 8.34175833 11.469975 9.049425 12.7798917 4.08384167 9.04080833 4.08384167 7.31839167 10.559175 5.90214167 11.0550917 5.40714167 12.871925 6.84630833 12.3292583 5.85539167 16.127925"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </Tooltip>
                                                            <Tooltip className={"s_logo_detail" + (window.confirm.is_payment_bep20 != "1" ? ' d-none' : '') + (network == constants.BSC.toLowerCase() ? " active" : "")} onClick={() => onChangeNetwork(constants.BSC.toLowerCase())} placement="top" title="USDT BSC (BNB Smart Chain(BEP20))">
                                                                <span>USDT BSC (BNB Smart Chain(BEP20))</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g transform="translate(-314.000000, -339.000000)">
                                                                            <g id="USDT" transform="translate(314.000000, 339.000000)">
                                                                                <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                                <g transform="translate(2.000000, 2.000000)">
                                                                                    <circle fill="#26A17B" cx="11" cy="11" r="11"/>
                                                                                    <polygon fill="#FFFFFF" points="5.92063404 5.37303338 16.0147414 5.37303338 16.0147414 7.88552154 5.92063404 7.88552154"/>
                                                                                    <polygon fill="#FFFFFF" points="9.61879094 7.54285714 12.2738779 7.54285714 12.2738779 11.4420531 9.61879094 11.4420531"/>
                                                                                    <polygon fill="#FFFFFF" points="9.61879094 11.9428571 12.2738779 11.9428571 12.2738779 17.6 9.61879094 17.6"/>
                                                                                    <path d="M11,9.42857143 C14.6450793,9.42857143 17.6,10.132124 17.6,11 C17.6,11.867876 14.6450793,12.5714286 11,12.5714286 C7.35492065,12.5714286 4.4,11.867876 4.4,11 C4.4,10.132124 7.35492065,9.42857143 11,9.42857143 Z M10.9463344,9.70017381 C7.64840549,9.70017381 5.02373604,10.3007899 5.02373604,10.8215155 C5.02373604,11.3422411 7.70207107,11.9428571 11,11.9428571 C14.2979289,11.9428571 16.9832526,11.3422411 16.9832526,10.8215155 C16.9832526,10.3007899 14.2442634,9.70017381 10.9463344,9.70017381 Z" fill="#FFFFFF"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </Tooltip>
                                                            <Tooltip className={"s_logo_detail" + (window.confirm.is_payment_btc != "1" ? ' d-none' : '') + (network == constants.Bitcoin.toLowerCase() ? " active" : "")} onClick={() => onChangeNetwork(constants.Bitcoin.toLowerCase())} placement="top" title="Bitcoin(BTC)">
                                                                <span>Bitcoin(BTC)</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g transform="translate(-214.000000, -339.000000)">
                                                                            <g transform="translate(214.000000, 339.000000)">
                                                                                <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                                <g transform="translate(2.000000, 2.000000)">
                                                                                    <path d="M11,0 L11,0 C17.07475,0 22,4.92525 22,11 C22,17.07475 17.07475,22 11,22 C4.92525,22 0,17.07475 0,11 C0,4.92525 4.92525,0 11,0" id="Fill-1" fill="#F9934A"/>
                                                                                    <path d="M10.0010412,10.8737501 L12.4475024,11.4915433 C13.1640437,11.6846902 13.5883935,12.4220882 13.3952466,13.1386295 C13.3888216,13.1622539 13.3818035,13.1857795 13.3741923,13.2090085 C13.1548509,14.0925023 12.2868762,14.287033 11.8574851,14.287033 C11.7942231,14.2890099 11.7308622,14.2827826 11.6690829,14.2685486 L9.30604851,13.6815956 L10.0010412,10.8737501 Z M10.9925746,6.8179622 L12.5587052,7.21018677 C12.82747,7.27819345 14.0784765,7.82807885 13.7942917,8.93694357 C13.6509636,9.56976154 13.0668772,10.0029087 12.4197264,9.95635184 C12.2920162,9.9560553 12.1647014,9.94261212 12.0397589,9.91612114 L10.3130021,9.48366588 L10.9925746,6.8179622 Z M6.92749513,13.1410018 C6.9059465,13.230656 6.89359063,13.2831437 6.76993314,13.2522046 L5.36136456,12.9001119 L4.99998023,14.3519754 L7.59787492,15.0006089 L7.07270125,17.1103975 L8.45962235,17.4563617 L8.98479603,15.3465731 L9.8466423,15.5628502 L9.32146863,17.6694757 L10.6404819,18.0000198 L11.1687198,15.8902312 L12.1602533,16.1404127 C12.8212426,16.304202 15.3264189,16.3350423 15.8670127,14.14499 C16.2236524,12.9392553 15.7028279,11.6466341 14.6097787,11.0250847 C14.6097787,11.0250847 16.1542618,10.5524976 16.3705389,9.13780051 C16.5867171,7.72300458 15.6013122,7.06507949 15.0298781,6.70359631 C14.5803223,6.44402431 14.1025952,6.23674232 13.6058894,6.08580308 L14.155676,3.88033065 L12.8860862,3.57143403 L12.3424281,5.7645506 L11.3231187,5.51130479 L11.8791326,3.30889662 L10.5941227,3 L10.0442373,5.20240817 L7.35688614,4.53212723 L7.02950515,5.82949302 L8.5029173,6.19700586 C8.65118768,6.23407346 8.63260446,6.30820864 8.62024859,6.3576321 L6.92749513,13.1410018 Z" id="Fill-1" fill="#FFFFFF"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </Tooltip>
                                                            <Tooltip className={"s_logo_detail" + (window.confirm.is_payment_bch != "1" ? ' d-none' : '') + (network == constants.BitcoinCash.toLowerCase() ? " active" : "")} onClick={() => onChangeNetwork(constants.BitcoinCash.toLowerCase())} placement="top" title="Bitcoin Cash(BCH)">
                                                                <span>Bitcoin Cash(BCH)</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g transform="translate(-214.000000, -339.000000)">
                                                                            <g transform="translate(214.000000, 339.000000)">
                                                                                <path d="M22,0.5 C22.9664983,0.5 23.8414983,0.891750844 24.4748737,1.52512627 C25.1082492,2.15850169 25.5,3.03350169 25.5,4 L25.5,4 L25.5,22 C25.5,22.9664983 25.1082492,23.8414983 24.4748737,24.4748737 C23.8414983,25.1082492 22.9664983,25.5 22,25.5 L22,25.5 L4,25.5 C3.03350169,25.5 2.15850169,25.1082492 1.52512627,24.4748737 C0.891750844,23.8414983 0.5,22.9664983 0.5,22 L0.5,22 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z" stroke="#F3F6FB" fill="#FFFFFF"/>
                                                                                <g transform="translate(2.000000, 2.000000)">
                                                                                    <path d="M11,0 L11,0 C17.07475,0 22,4.92525 22,11 C22,17.07475 17.07475,22 11,22 C4.92525,22 0,17.07475 0,11 C0,4.92525 4.92525,0 11,0" id="Fill-1" fill="#F9934A"/>
                                                                                    <path d="M10.0010412,10.8737501 L12.4475024,11.4915433 C13.1640437,11.6846902 13.5883935,12.4220882 13.3952466,13.1386295 C13.3888216,13.1622539 13.3818035,13.1857795 13.3741923,13.2090085 C13.1548509,14.0925023 12.2868762,14.287033 11.8574851,14.287033 C11.7942231,14.2890099 11.7308622,14.2827826 11.6690829,14.2685486 L9.30604851,13.6815956 L10.0010412,10.8737501 Z M10.9925746,6.8179622 L12.5587052,7.21018677 C12.82747,7.27819345 14.0784765,7.82807885 13.7942917,8.93694357 C13.6509636,9.56976154 13.0668772,10.0029087 12.4197264,9.95635184 C12.2920162,9.9560553 12.1647014,9.94261212 12.0397589,9.91612114 L10.3130021,9.48366588 L10.9925746,6.8179622 Z M6.92749513,13.1410018 C6.9059465,13.230656 6.89359063,13.2831437 6.76993314,13.2522046 L5.36136456,12.9001119 L4.99998023,14.3519754 L7.59787492,15.0006089 L7.07270125,17.1103975 L8.45962235,17.4563617 L8.98479603,15.3465731 L9.8466423,15.5628502 L9.32146863,17.6694757 L10.6404819,18.0000198 L11.1687198,15.8902312 L12.1602533,16.1404127 C12.8212426,16.304202 15.3264189,16.3350423 15.8670127,14.14499 C16.2236524,12.9392553 15.7028279,11.6466341 14.6097787,11.0250847 C14.6097787,11.0250847 16.1542618,10.5524976 16.3705389,9.13780051 C16.5867171,7.72300458 15.6013122,7.06507949 15.0298781,6.70359631 C14.5803223,6.44402431 14.1025952,6.23674232 13.6058894,6.08580308 L14.155676,3.88033065 L12.8860862,3.57143403 L12.3424281,5.7645506 L11.3231187,5.51130479 L11.8791326,3.30889662 L10.5941227,3 L10.0442373,5.20240817 L7.35688614,4.53212723 L7.02950515,5.82949302 L8.5029173,6.19700586 C8.65118768,6.23407346 8.63260446,6.30820864 8.62024859,6.3576321 L6.92749513,13.1410018 Z" id="Fill-1" fill="#FFFFFF"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cun_telegram_detail pay_virtual_currency">
                                                    <span className="cun_t_d_step">Step2</span>
                                                    <div className="bind_taxtid">
                                                        <p className='mb-2'>Click to continue to pay to complete the payment</p>
                                                        <Button type="primary" loading={loadingButton} onClick={handlePayment} className="pay_btn">Continue to Pay</Button>
                                                        <div className="pay_btn_tips mt-2">
                                                            <p>
                                                                By clicking continue to pay, you agree to our &nbsp;
                                                                <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target="_blank">Terms of Service</a> 
                                                                &nbsp; and &nbsp;
                                                                <a href="https://shopsocks5.com/faq-detail/Refund_Policy_and_Dispute_Resolution" target="_blank">Privacy Policy</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <div className='col-12 col-md-4 order-1 order-md-2'>
                    <div className="plan panel pt-0 mb-3">
                        <div className="panel-body bg-white panel-border-top p-3">
                        {
                            loading ? (
                                <div className='text-center'><Spin /></div>
                            ) : (
                                <>
                                    <h4 className='text-bold mb-3'>{packagePlan?.plan_name}</h4>
                                    <table className="table table-striped table-not-mobile">
                                        <tbody>
                                            <tr>
                                                <td width="50%">
                                                    <strong>Price</strong>
                                                </td>
                                                <td className="text-left">
                                                    {
                                                        method == constants.Point.toLowerCase() ? (packagePlan?.plan_price * 10 + ' Point') : ((packagePlan?.plan_price) + '$')
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                packagePlan?.discount && (
                                                    <tr>
                                                        <td width="50%">
                                                            <strong>Discount ({ packagePlan?.discount.discount }%)</strong>
                                                        </td>
                                                        <td className="text-left">
                                                            {
                                                                method == constants.Point.toLowerCase() ? (calculatorDiscount() * 10 + ' Point') : ((calculatorDiscount()) + '$')
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                fee > 0 && (
                                                    <tr>
                                                        <td width="50%">
                                                            <strong>Handling Fees ({fee}%)</strong>
                                                        </td>
                                                        <td className="text-left">
                                                            {calculatorFee()}$
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                network != '' && (
                                                    <tr>
                                                        <td width="50%">
                                                            <strong>Payment Method</strong>
                                                        </td>
                                                        <td className="text-left">{ getCoinName(network) }</td>
                                                    </tr>
                                                )
                                            }
                                            <tr>
                                                <td width="50%">
                                                    <strong>Total</strong>
                                                </td>
                                                <td className="text-left">
                                                    <b className='text-danger'>
                                                        {calculatorTotal()}
                                                    </b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div id="div_element" className="d-none"></div>
        </div>
    );
}
export default Payment;
