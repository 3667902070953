import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Pagination, Modal, Checkbox, Radio, Switch, Input, Dropdown, Select, Button, Menu, Spin, Popconfirm, Form, Tooltip, notification, message } from 'antd';
import { EditOutlined, CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { account, plan, authenticator, proxy } from '../../endpoint';
import { getParamsByUrlPaging, copyTextToClipboard, downloadCSV } from '../../_until';
import AppContext from './../../_until/AppContext';
import CountdownTimer from './../website/include/CountdownTimer';

import icBuyPoint from '../../assets/images/ic-buy-point.png';

const ProxyListComponent = () => {
    const history = useHistory();
    const [formSearch] = Form.useForm()
    const [formNote] = Form.useForm();
    const [formInstall] = Form.useForm();
    const [formBulkAction] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false);
    const [dataLists, setDataLists] = useState([]);
    const [dataListPackageProxy, setListPackageProxy] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [countChangeIp, setCountChangeIp] = useState(0);
    const [current, setCurrent] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [dataForm, setDataForm] = useState({});
    const [checkAll, setCheckAll] = useState(false);
    const [dataState, setDataState] = useState(null);
    const [bulkType, setBulkType] = useState('');
    const [isModalVisibleReinstall, setModalVisibleReinstall] = useState(false);
    const [isModalVisibleChangeIp, setModalVisibleChangeIp] = useState(false);
    const [isModalVisibleNote, setModalVisibleNote] = useState(false);
    const [isModalVisibleRenew, setModalVisibleRenew] = useState(false);
    const [isModalVisibleDetail, setModalVisibleDetail] = useState(false);
    const [isModalVisibleBulkAction, setModalVisibleBulkAction] = useState(false);
    const [listIps, setListIps] = useState([]);
    const [currentRow, setCurrentRow] = useState({});
    const [month, setMonth] = useState(1);
    const [dataService, setDataService] = useState({});
    const [profile, setProfile] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);

    /**
     * On change page
     *
     * @param page
     * @param pageSize
     */
    const onChange = (page, pageSize) =>{
        setCurrent(page);
        setPerPage(pageSize);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    /**
     * On change page
     *
     * @param values
     */
    const searchForm = (values) => {
        setLoadingButtonSearch(true);
        setDataForm(values);
        loadList({current: 1, pageSize: 20, ...values});
    }

    /**
     * Load list proxy
     *
     * @param values
     */
    const loadList = (values, isShowLoading = true) => {
        let hide = null;
        if (isShowLoading) {
            hide = message.loading('Processing...');
        }
        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        proxy.getListFrontend(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setDataService(res?.data_service);
                setFirst(false);
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            if (isShowLoading) {
                setLoading(false);
                setLoadingButtonSearch(false);
            }
        }).then(complete => {
            if (isShowLoading) {
                hide();
                setLoading(false);
                setLoadingButtonSearch(false);
            }
        });
    }

    /**
     * Change auto renew
     *
     * @param status
     * @param record
     */
    const handledChangeAutoRenew = async (status, record) => {
        const hide = message.loading('Processing...');
        try {
            var res = await proxy.changeAutoRenewFrontend(record.id, {'is_renew': status ? 1 : 0 });
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Update successfully',
                    type: 'success'
                });
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Update failed, please try again!',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * Handle reinstall
     *
     * @param record
     * @param values
     */
    const handledReinstall = async (record, values) => {
        const hide = message.loading('Processing...');
        try {
            setLoadingButton(true);
            var res = await proxy.reinstallFrontend(record.id, {...values});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                setLoadingButton(false);
                setModalVisibleReinstall(false);
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Reinstall failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    };

    /**
     * Handle Renew
     *
     * @param record
     * @param values
     */
    const handledRenew = async (record, values) => {
        const hide = message.loading('Processing...');
        setLoadingButton(true);
        try {
            var res = await proxy.renewFrontend(record.id, {...values});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                loadList({all: 1, ...paramQuery});
                setModalVisibleRenew(false);
                setLoadingButton(false);
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Renew failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    };

    /**
     * Handle change ip
     *
     * @param record
     * @param values
     */
    const handledChangeIp = async (record, values) => {
        const hide = message.loading('Processing...');
        try {
            setLoadingButton(true);
            var res = await proxy.changeIpFrontend(record.id, {...values});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                setLoadingButton(false);
                setModalVisibleChangeIp(false);
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Renew failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    };

    /**
     * Handle auto fix
     *
     * @param record
     */
    const handledAutoFix = async (record) => {
        const hide = message.loading('Processing...');
        try {
            var res = await proxy.autoFixFrontend(record.id);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Auto fix successfully',
                    type: 'success'
                });
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Auto fix failed, please try again!',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * Handle pause
     *
     * @param record
     */
    const handledPause = async (record) => {
        const hide = message.loading('Processing...');
        try {
            var res = await proxy.pauseActionFrontend(record.id);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Pause failed, please try again!',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * Handle reboot
     *
     * @param record
     */
    const handledReboot = async (record) => {
        const hide = message.loading('Processing...');
        try {
            var res = await proxy.rebootFrontend(record.id);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Reboot failed, please try again!',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * Handle Note
     *
     * @param record
     * @param values
     */
    const handledNote = async (record, values) => {
        const hide = message.loading('Processing...');
        try {
            setLoadingButton(true);
            var res = await proxy.noteProxyFrontend(record.id, {...values});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Note successfully',
                    type: 'success'
                });
                setModalVisibleNote(false);
                setLoadingButton(false);
                loadList({all: 1, ...paramQuery});
                setLoadNotification(true);
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Note failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    };

    /**
     * Handle Bulk actions
     *
     * @param values
     */
    const handledBulkAction = async (values) => {
        if (listIps.length == 0) {
            notification.open({
                message: 'Fail!',
                description: "Please choose item to bulk action!",
                type: 'error'
            });
            return false;
        }

        const hide = message.loading('Processing...');
        try {
            setLoadingButton(true);
            if (values.action != 'change-ip' && values.action != 'copy') {
                var res = await proxy.bucketActionFrontend({'ids': listIps, ...values});
                hide();
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                        type: 'success'
                    });
                    setModalVisibleBulkAction(false);
                    setLoadingButton(false);
                    setListIps([]);
                    setCheckAll(false);
                    loadList({all: 1, ...paramQuery});
                    setLoadNotification(true);
                    return true;
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            } else {
                if (values.action == 'change-ip') {
                    var checkPrice = await proxy.checkPriceChangeIpFrontend(listIps);
                    if (checkPrice.status == 'success') {
                        var isSuccess = false;
                        for (let index = 0; index < listIps.length; index++) {
                            const id = listIps[index];
                            var res = await proxy.changeIpFrontend(id, {});
                            if (res.status == 'success') {
                                notification.open({
                                    message: 'Success',
                                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                                    type: 'success'
                                });
                                isSuccess = true;
                            } else {
                                if (typeof res.message != 'undefined') {
                                    notification.open({
                                        message: 'Fail!',
                                        description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                                        type: 'error'
                                    });
                                }
                            }
                        }
                        if (isSuccess) {
                            setModalVisibleBulkAction(false);
                            setLoadingButton(false);
                            setListIps([]);
                            setCheckAll(false);
                            loadList({all: 1, ...paramQuery});
                            setLoadNotification(true);
                        }
                    } else {
                        if (typeof res.message != 'undefined') {
                            notification.open({
                                message: 'Fail!',
                                description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                                type: 'error'
                            });
                        }
                    }
                } else {
                    copyList();
                    hide();
                    setModalVisibleBulkAction(false);
                    setLoadingButton(false);
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Bulk action failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    };

    /**
     * Check item proxy
     *
     * @param id
     * @param e
     */
    const onChangeCheckItem = (id, e) => {
        if (e.target.checked) {
            var listIpsNew = [];
            for (let index = 0; index < listIps.length; index++) {
                listIpsNew.push(listIps[index])
            }
            listIpsNew.push(id);
            if (listIpsNew.length == dataLists.length) {
                setCheckAll(true);
            } else {
                setCheckAll(false);
            }
            setListIps(listIpsNew);
        } else {
            let listIpsNew = listIps.filter(function(item) {
                return item !== id
            });
            setListIps(listIpsNew);
            setCheckAll(false);
        }
    }

    /**
     * Check item proxy
     *
     * @param e
     */
    const onChangeCheckAll = (e) => {
        if (e.target.checked) {
            var listIpsNew = [];
            for (let index = 0; index < dataLists.length; index++) {
                var item = dataLists[index];
                if (item.status != 0 && item.status != 3 && item?.is_trial != 1) {
                    listIpsNew.push(dataLists[index].id);
                }
            }
            setListIps(listIpsNew);
            setCheckAll(true);
        } else {
            setListIps([]);
            setCheckAll(false);
        }
    }

    /**
     * Handle close modal
     */
    const handleCancel = () => {
        setModalVisibleReinstall(false);
        setModalVisibleChangeIp(false);
        setModalVisibleNote(false);
        setModalVisibleRenew(false);
        setModalVisibleDetail(false);
        setModalVisibleBulkAction(false);
    }

    /**
     * Handle change month
     *
     * @param e
     */
    const onChangeMonth = (e) => {
        setMonth(e.target.value);
    };

    /**
     * Handle click menu item
     *
     * @param key
     */
    const handleClick = ({key}) => {
        if (key == 'information') {
            setModalVisibleDetail(true);
        } else if (key == 'reinstall') {
            setModalVisibleReinstall(true);
        } else if (key == 'change-ip') {
            const hide = message.loading('Processing...');
            proxy.getContentChangeIpFrontend(currentRow.id).then(res => {
                if (res.status == 'success') {
                    setDataState(res.state);
                    setMonth(res.proxy.month);
                    setCountChangeIp(res.countChangeIp);
                    setModalVisibleChangeIp(true);
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }
                hide();
            }).then(res => {
            });
        } else if (key == 'renew') {
            setModalVisibleRenew(true);
        }
    };

    /**
     * Handle calculater credit by month
     */
    const handledCalculaterDate = () => {
        let dt = new Date(currentRow.expiration_date);
        dt.setDate(dt.getDate() + month * 30);
        return dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    }

    /**
     * Handle copy text
     *
     * @param textCopy
     */
    const handleCopyText = (textCopy) => {
        if (copyTextToClipboard(textCopy)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Handle copy list
     */
    const copyList = () => {
        let textFill = '';
        for (let index = 0; index < dataLists.length; index++) {
            const item = dataLists[index];
            if (listIps.includes(item.id)) {
                textFill = textFill + item?.ip + ':' + item?.port + ':' + item?.username + ':' + item?.password + '\n';
            }
        }
        if (copyTextToClipboard(textFill)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Export Csv
     */
    const exportCsv = () => {
        let dataCsv = [];
        dataCsv.push(["IP", "User Name", 'Password', 'Country', 'Type Proxy', 'Price', 'Expired', 'Note']);
        for (let index = 0; index < dataLists.length; index++) {
            const item = dataLists[index];
            dataCsv.push([item?.ip + ':' + item?.port, item?.username, item?.password, item.plan_name, item.type == 'proxy_sock_5' ? 'SOCKS5 Proxy' : 'HTTPS Proxy', item.credit + ' Point', item.expiration_date, item.note != null ? item.note : '']);
        }
        downloadCSV(dataCsv, 'export-proxy.csv');
    }

    /**
     * List menu item config proxy
     */
    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="information">
                <i className="fa fa-eye pr-1"></i>
                Proxy information
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="pause">
                <Popconfirm
                    title="Do you really want to pause use the proxy?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handledPause(currentRow)}
                >
                    <i className="fa fa-power-off pr-1"></i> 
                    Pause Proxy
                </Popconfirm>
            </Menu.Item>
            <Menu.Item key="reboot" className="text-danger">
                <Popconfirm
                    title="Do you really want to reboot the proxy?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handledReboot(currentRow)}
                >
                    <i className="fa fa-sun pr-1"></i> 
                    Reboot Proxy
                </Popconfirm>
            </Menu.Item>
            <Menu.Item key="reinstall">
                <i className="fas fa-sync pr-1"></i> 
                Reinstall proxy
            </Menu.Item>
            {
                currentRow?.is_trial != 1 && (
                    <>
                        <Menu.Item key="change-ip">
                            <i className="fa fa-edit pr-1"></i> 
                            Change IP proxy
                        </Menu.Item>
                        <Menu.Item key="renew">
                            <i className="fas fa-redo-alt pr-1"></i> 
                            Renew proxy
                        </Menu.Item>
                    </>
                )
            }
        </Menu>
    );

    /**
     * List status item
     */
    const getStatus = (status) => {
        if (status == 1) {
            return (<span className='badge badge-success text-bold'>Running</span>);
        } else if (status == 2) {
            return (<span className='badge badge-danger text-bold'>Expired</span>);
        } else if (status == 3) {
            return (<span className='badge badge-dark text-bold'>Banned</span>);
        } else if (status == 5) {
            return (<span className='badge badge-warning text-bold'>Pause</span>);
        } else if (status == 6) {
            return (<span className='badge badge-info text-bold'>Initialize</span>);
        }
        return (<span className='badge badge-secondary text-bold'>Deactive</span>);
    }

    /**
     * Handle get info profile
     */
    const getProfile = () => {
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Proxy Manages - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadList({all: 1, ...paramQuery});
                    if(paramQuery?.current){
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                    getProfile();
                }
            }).then(res => {
            });

            plan.getListPackageProxy().then(res => {
                if (res.status == 'success') {
                    setListPackageProxy(res.response.proxy);
                }
            }).then(complete => {
            });
            var pusher = new Pusher(window?.appKey, {
                cluster: window?.cluster
            });

            var channel = pusher.subscribe('my-channel');
            channel.bind('event-live-proxy', function(data) {
                console.log(data);
                if (data?.user_id == user?.user_id) {
                    loadList({current: current, pageSize: perPage, ...dataForm}, false);
                }
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container size-lg">
            <div className="content mb-2">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className='row no-gutters top-service'>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">SS5 Point</div>
                                    <div className="panel-body bg-primary">
                                        <h3 className='text-white'>{profile?.credit}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Buy Now</div>
                                    <div className="panel-body">
                                        <div>
                                            <Link to='/buy-point.html' className='btn btn-primary mb-3'><img src={icBuyPoint} width="18px" /> Buy Point Now</Link><br/>
                                            <Link to='/buy-socks5.html?tab=static_proxies' className='btn btn-primary'><i className="fa fa-shopping-cart pr-2"></i> Buy Proxy Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Total Proxies</div>
                                    <div className="panel-body">
                                        <h3>{total}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Running</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.running}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Pause</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.pause}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Banned</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.banned}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-auto col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Expired</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.expired}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="content">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <>
                            <div className="panel panel-default panel-custom">
                                <div className="panel-heading">Static ISP Proxy Manage</div>
                                <div className="panel-body p-0">
                                    <div className='mb-3 mt-3'>
                                        <Form className="row" form={formSearch} onFinish={searchForm}>
                                            <div className='col-12 col-md-3'>
                                                <div className='mb-2 mt-2'>
                                                    <Button type="primary" htmlType="button" onClick={() => {
                                                        if (listIps.length == 0) {
                                                            notification.open({
                                                                message: 'Fail!',
                                                                description: "Please choose proxy to bulk action!",
                                                                type: 'error'
                                                            });
                                                            return false;
                                                        } else {
                                                            setModalVisibleBulkAction(true);
                                                        }
                                                    }} className="medium">Bulk Actions</Button>
                                                    {
                                                        dataLists.length > 0 && (<Button type="primary" htmlType="button" className="ml-3 mt-3" onClick={exportCsv}>Export CSV</Button>)
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-3'>
                                                <Form.Item
                                                    label="IP:"
                                                    labelCol={{span: 24}}
                                                    name="keyword"
                                                    initialValue={dataForm?.keyword}
                                                >
                                                    <Input placeholder="IP" />
                                                </Form.Item>
                                            </div>
                                            <div className='col-12 col-md-3'>
                                                <Form.Item
                                                    label="Country:"
                                                    labelCol={{span: 24}}
                                                    name="country"
                                                    initialValue={dataForm?.country}
                                                >
                                                    <Select style={{width: "100%"}} placeholder="Country">
                                                        <Option value="">All</Option>
                                                        <Option value="SG">Proxy Singapore (SG)</Option>
                                                        <Option value="AU">Proxy Australia (AU)</Option>
                                                        <Option value="US">Proxy US (US)</Option>
                                                        <Option value="CA">Proxy Canada (CA)</Option>
                                                        <Option value="UK">Proxy UK (UK)</Option>
                                                        <Option value="DE">Proxy Germany (DE)</Option>
                                                        <Option value="FR">Proxy France (FR)</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className='col-12 col-md-2'>
                                                <Form.Item
                                                    label="Proxy Type:"
                                                    labelCol={{span: 24}}
                                                    name="type"
                                                    initialValue={dataForm?.type}
                                                >
                                                    <Select style={{width: "100%"}} placeholder="Proxy Type">
                                                        <Option value="">All</Option>
                                                        <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                                        <Option value="proxy_https">HTTPS Proxy</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className='col-12 col-md-1'>
                                                <label className='d-block mb-1'>&nbsp;</label>
                                                <Button type="primary" loading={loadingButtonSearch} htmlType="submit" className='mb-3'><SearchOutlined className='text-white' /></Button>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-list-mobile">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Checkbox checked={checkAll} onChange={onChangeCheckAll}>#</Checkbox>
                                                    </th>
                                                    <th>IP</th>
                                                    <th>Username</th>
                                                    <th>Password</th>
                                                    <th>Price</th>
                                                    <th>Country</th>
                                                    <th>Type Proxy</th>
                                                    <th>Auto Renew</th>
                                                    <th>Date</th>
                                                    <th>Expired</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                    <th>Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataLists.length > 0 && dataLists.map((item, key) => 
                                                        <>
                                                            <tr key={'key_1' + item.id} className={(item.is_expired_left3day ? 'bg-expired_left3day' : '') + (item.status == 3 ? ' bg-status-banned' : '')}>
                                                                <td data-title="#">
                                                                    {
                                                                        item.status != 0 && item.status != 3 && item?.is_trial != 1 && (
                                                                            <Checkbox key={"check_item" + item.id} checked={checkAll || listIps.includes(item.id)} onChange={(e) => {onChangeCheckItem(item.id, e)}}>{((current - 1)*20) + key + 1}</Checkbox>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td data-title="IP">
                                                                    <Tooltip title="Copy IP:PORT" key={"copy_ip" + item.id}>
                                                                        <a onClick={() => handleCopyText(item?.ip + ':' + item?.port)} className='d-flex align-items-center'>{item?.ip}:{item?.port} <CopyOutlined className='pl-1' /></a>
                                                                    </Tooltip>
                                                                </td>
                                                                <td data-title="Username">
                                                                    <Tooltip title="Copy Username" key={"copy_user" + item.id}>
                                                                        <a onClick={() => handleCopyText(item?.username)} className='d-flex align-items-center'>{item?.username} <CopyOutlined className='pl-1' /></a>
                                                                    </Tooltip>
                                                                </td>
                                                                <td data-title="Password">
                                                                    <Tooltip title="Copy Password" key={"copy_password" + item.id}>
                                                                        <a onClick={() => handleCopyText(item?.password)} className='d-flex align-items-center'>{item?.password} <CopyOutlined className='pl-1' /></a>
                                                                    </Tooltip>
                                                                </td>
                                                                <td data-title="Price">{item.credit} Point</td>
                                                                <td data-title="Country">{item.plan_name}</td>
                                                                <td data-title="Type Proxy">{item.type == 'proxy_sock_5' ? 'SOCKS5 Proxy' : 'HTTPS Proxy'}</td>
                                                                <td data-title="Auto Renew">
                                                                    {
                                                                        item?.is_trial != 1 && (
                                                                            <Switch key={"is_trial" + item.id} checkedChildren="Open" unCheckedChildren="Off" onChange={(value) => handledChangeAutoRenew(value, item)} defaultChecked={item?.is_renew  == 1 ? true : false} />
                                                                        )
                                                                    }
                                                                </td>
                                                                <td data-title="Date">{item.date}</td>
                                                                <td data-title="Expired">
                                                                    {
                                                                        item?.is_trial != 1 ? item.expiration_date_format : (
                                                                            <CountdownTimer targetDate={item.expiration_date} show={item.expiration_date_format} />
                                                                        )
                                                                    }
                                                                </td>
                                                                <td data-title="Status">{getStatus(item.status)}</td>
                                                                <td data-title="Action">
                                                                    {
                                                                        item.status != 0 && item.status != 3 ? (
                                                                            <Dropdown overlay={menu} trigger={["click"]}>
                                                                                <a
                                                                                    className="ant-dropdown-link btn btn-primary btn-sm"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setCurrentRow(item);
                                                                                        formInstall.setFieldsValue({'type': item.type});
                                                                                    }}
                                                                                    key={"setting-dropdown" + item.id}
                                                                                >
                                                                                    <i className="fa fa-cogs" aria-hidden="true"></i> Setting
                                                                                </a>
                                                                            </Dropdown>
                                                                        ) : (
                                                                            <a
                                                                                className="ant-dropdown-link btn btn-primary btn-sm"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setCurrentRow(item);
                                                                                    setModalVisibleDetail(true);
                                                                                }}
                                                                                key={"information" + item.id}
                                                                            >
                                                                                <i className="fa fa-cogs" aria-hidden="true"></i> Information
                                                                            </a>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td data-title="Note">
                                                                    {item.note}
                                                                    <a key={"edit-note" + item.id} className='pl-1'
                                                                        onClick={() => {
                                                                            setCurrentRow(item);
                                                                            formNote.resetFields();
                                                                            setModalVisibleNote(true);
                                                                        }}
                                                                    ><EditOutlined /></a>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                    <td colSpan="13" className="text-center">
                                                        No data.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        total > 20 && !loading ? (
                                            <div className="paging mt-4">
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize={perPage} onChange={onChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
            </div>

            <Modal visible={isModalVisibleReinstall} title="Reinstall proxy" footer={false} onCancel={handleCancel} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            name="basic"
                            form={formInstall}
                            className="form"
                            onFinish={(values) => { handledReinstall(currentRow, values) }}
                        >
                             <Form.Item
                                label="Proxy Type:"
                                labelCol={{span: 24}}
                                name="type"
                                rules={[{ required: true, message: 'Please choose Proxy Type!' }]}
                            >
                                <Select style={{width: "100%"}} placeholder="Proxy Type">
                                    <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                    <Option value="proxy_https">HTTPS Proxy</Option>
                                </Select>
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button type="primary" htmlType="submit" loading={loadingButton} className="medium">Submit</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleChangeIp} title="Change Ip" footer={false} onCancel={handleCancel} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            name="basic"
                            className="form"
                            onFinish={(values) => { handledChangeIp(currentRow, values) }}
                        >
                            <p>
                                Note:<br/>
                                - Each Proxy can change IP up to 35 times per month<br/>
                                - Can change IP for free 5 times in 1 month<br/>
                                - Subsequent ip changes will cost <b className='text-danger'>2 Point/1 ip</b> change times<br/>
                                You have now changed your IP {countChangeIp} in the month<br/>
                                Do you really want to change your IP address?
                            </p>
                            <Form.Item
                                label="Proxy Type:"
                                labelCol={{span: 24}}
                                name="type"
                                rules={[{ required: true, message: 'Please choose Proxy Type!' }]}
                            >
                                <Select style={{width: "100%"}} placeholder="Proxy Type">
                                    <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                    <Option value="proxy_https">HTTPS Proxy</Option>
                                </Select>
                            </Form.Item>
                            {
                                dataState && dataState.length > 0 ? (
                                    <Form.Item name="state" label="State:" labelCol={{span: 24}}>
                                        <Select
                                            style={{width: "100%"}}
                                            placeholder="Random"
                                            defaultValue="random"
                                        >
                                            <Option value="random">Random</Option>
                                            {
                                                dataState.map((item, key) => 
                                                    <Option key={key} value={item}>{item}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                ) : ''
                            }
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button type="primary" htmlType="submit" loading={loadingButton} className="medium">Change IP</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleNote} title="Note" footer={false} onCancel={handleCancel} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            name="basic"
                            form={formNote}
                            className="form"
                            onFinish={(values) => { handledNote(currentRow, values) }}
                        >
                            <Form.Item
                                label="Note:"
                                labelCol={{span: 24}}
                                name="note"
                                rules={[{ required: true, message: 'Please enter Note!' }]}
                            >
                                <Input.TextArea rows={4} defaultValue={currentRow?.note} />
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button type="primary" htmlType="submit" loading={loadingButton} className="medium">Submit</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleRenew} title="Renew proxy" footer={false} onCancel={handleCancel} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            name="basic"
                            className="form"
                            onFinish={(values) => { handledRenew(currentRow, values) }}
                        >
                            <Form.Item
                                label="Payment time:"
                                labelCol={{span: 24}}
                                name="month"
                                rules={[{ required: true, message: 'Please choose payment time!'}]}
                                initialValue={month}
                            >
                                <Radio.Group onChange={onChangeMonth} value={month}>
                                    <Radio value={1}>1 Months</Radio>
                                    <Radio value={3}>3 Months</Radio>
                                    <Radio value={6}>6 Months</Radio>
                                    <Radio value={12}>12 Months</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <div className="form-group price-wrap">
                                <p className="mb-0">Current expiration date: <b>{currentRow?.expiration_date_format}</b></p>
                                <p className="mb-0">Extension time: <b>{month} Months</b></p>
                                <p className="mb-0">Payment credit: <b>{month * currentRow.credit} Creidt</b></p>
                                <p className="mb-0">Expiration date after renewal: <b>{handledCalculaterDate()}</b></p>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button type="primary" htmlType="submit" loading={loadingButton} className="medium">Submit</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleDetail} title="Proxy Infomation" footer={false} onCancel={handleCancel} width={640}>
                {
                    currentRow?.description ? (
                        <div className="alert alert-danger" role="alert">
                            {currentRow?.description}
                        </div>
                    ) : ''
                }
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h3>{currentRow?.title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <table className="table table-not-mobile">
                            <tr>
                                <td><b>Ip Address:</b></td>
                                <td>
                                    <Tooltip title="Copy IP:PORT">
                                        <a onClick={() => handleCopyText(currentRow?.ip + ':' + currentRow?.port)} className='d-flex align-items-center'>{currentRow?.ip}:{currentRow?.port} <CopyOutlined className='pl-1' /></a>
                                    </Tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Username:</b></td>
                                <td>
                                    <Tooltip title="Copy Username">
                                        <a onClick={() => handleCopyText(currentRow?.username)} className='d-flex align-items-center'>{currentRow?.username} <CopyOutlined className='pl-1' /></a>
                                    </Tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Password:</b></td>
                                <td>
                                    <Tooltip title="Copy Password">
                                        <a onClick={() => handleCopyText(currentRow?.password)} className='d-flex align-items-center'>{currentRow?.password} <CopyOutlined className='pl-1' /></a>
                                    </Tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Expired:</b></td>
                                <td>{currentRow?.expiration_date_format}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-12 col-md-6">
                        <table className="table table-not-mobile">
                            <tr>
                                <td><b>Location:</b></td>
                                <td>{currentRow?.country}</td>
                            </tr>
                            <tr>
                                <td><b>Type Proxy:</b></td>
                                <td>{currentRow?.type == 'proxy_sock_5' ? 'SOCKS5 Proxy' : 'HTTPS Proxy'}</td>
                            </tr>
                            <tr>
                                <td><b>Status:</b></td>
                                <td>{getStatus(currentRow?.status)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleBulkAction} title="Bulk actions" footer={false} onCancel={handleCancel} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            name="basic"
                            form={formBulkAction}
                            className="form"
                            onFinish={(values) => { handledBulkAction(values) }}
                        >
                            <Form.Item
                                label="Action:"
                                labelCol={{span: 24}}
                                name="action"
                                rules={[{ required: true, message: 'Please choose Action!' }]}
                            >
                                <Select style={{width: "100%"}} placeholder="Action" onChange={(value) => {setBulkType(value);}}>
                                    <Option value="renewProxy">Renew proxy</Option>
                                    <Option value="reinstall">Reinstall proxy</Option>
                                    <Option value="reboot">Reboot proxy</Option>
                                    <Option value="pause">Pause</Option>
                                    <Option value="change-ip">Change IP proxy</Option>
                                    <Option value="copy">Copy Proxy (Ip:Port:Username:Password)</Option>
                                </Select>
                            </Form.Item>
                            {
                                bulkType == 'reinstall' && (
                                    <Form.Item
                                        label="Proxy Type:"
                                        labelCol={{span: 24}}
                                        name="type"
                                        rules={[{ required: true, message: 'Please choose Proxy Type!' }]}
                                    >
                                        <Select style={{width: "100%"}} placeholder="Proxy Type">
                                            <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                            <Option value="proxy_https">HTTPS Proxy</Option>
                                        </Select>
                                    </Form.Item>
                                )
                            }
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button type="primary" htmlType="submit" loading={loadingButton} className="medium">Submit</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default ProxyListComponent;