import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Pagination, Modal, Spin, notification, message } from 'antd';
import { account, authenticator } from '../../endpoint';
import { getParamsByUrlPaging, constants } from '../../_until';

const OrdersList = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [dataLists, setDataLists] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [dataForm, setDataForm] = useState({});
    const [isModalVisibleDetail, setModalVisibleDetail] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle change page
     *
     * @param page
     * @param pageSize
     */
    const onChange = (page, pageSize) =>{
        setCurrent(page);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    /**
     * Load list order
     *
     * @param values
     */
    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);

        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        account.get_list_orders(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setFirst(false);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    /**
     * Show payment method
     *
     * @param type
     */
    const getPaymentMethod = (type) => {
        if (type == null) {
            return '';
        }
        if (type.toLowerCase() == constants.PerfectMoney.toLowerCase()) {
            return 'Perfect Money'
        } else if (type.toLowerCase() == constants.WebMoney.toLowerCase()) {
            return 'WebMoney';
        } else if (type.toLowerCase() == constants.Bitcoin.toLowerCase()) {
            return 'Bitcoin (BTC)';
        } else if (type.toLowerCase() == constants.BitcoinCash.toLowerCase()) {
            return 'Bitcoin Cash (BCH)';
        } else if (type.toLowerCase() == constants.ERC20.toLowerCase()) {
            return 'USDT (ERC20)';
        } else if (type.toLowerCase() == constants.TRC20.toLowerCase()) {
            return 'USDT (TRC20)';
        } else if (type.toLowerCase() == constants.BSC.toLowerCase()) {//'bep20'
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else if (type.toLowerCase() == constants.Credit.toLowerCase()) {
            return 'SS5 Point';
        } else if (type.toLowerCase() == constants.Payeer.toLowerCase()) {
            return 'Payeer';
        } else if (type.toLowerCase() == constants.Litecoin.toLowerCase()) {
            return 'Litecoin';
        } else {
            return 'Other';
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Orders History - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadList({all: 1, ...paramQuery});
                    if(paramQuery?.current){
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            <div className="content">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">Orders History</div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Plan</th>
                                                <th>Price</th>
                                                <th>Method</th>
                                                <th>Status</th>
                                                <th>Date Expired</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((current - 1)*20) + key + 1}</td>
                                                        <td data-title="Plan">{item.plan != null ? item?.plan : item?.package}</td>
                                                        <td data-title="Price">{item?.order_paygate == 'credit' ? item.order_amount * 10 : item.order_amount}{item?.order_paygate == 'credit' ? ' Point' : '$'}</td>
                                                        <td data-title="Method">{getPaymentMethod(item.order_paygate)}</td>
                                                        <td data-title="Status">{item.status == 2 ? (<span className='badge badge-danger text-bold'>Cancel</span>) : (<span className='badge badge-success text-bold'>Complete</span>)}</td>
                                                        <td data-title="Date Expired">{item.order_plan_id != null && item?.plan_type != 'buy_credit' ? item?.date_expired : ''}</td>
                                                        <td data-title="Description">{item.description}</td>
                                                        <td data-title="Date">{item.date}</td>
                                                        <td className='no-padding' data-title=""><a onClick={() => {setModalVisibleDetail(true); setOrderDetail(item); }}><i className="fa fa-eye pr-1"></i> View Detail</a></td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="8" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    total > 20 && !loading ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize="20" onChange={onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <Modal visible={isModalVisibleDetail} title="Order Infomation" footer={false} onCancel={() => { setModalVisibleDetail(false) }} width={640}>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h3>Detail order</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <table className="table table-not-mobile">
                            <tr>
                                <td><b>Plan:</b></td>
                                <td>{orderDetail?.plan != null ? orderDetail?.plan : orderDetail?.package}</td>
                            </tr>
                            <tr>
                                <td><b>Price:</b></td>
                                <td>{orderDetail?.order_paygate == 'credit' ? orderDetail.order_amount * 10 : orderDetail.order_amount} {orderDetail?.order_paygate == 'credit' ? 'Point' : '$'}</td>
                            </tr>
                            <tr>
                                <td><b>Method:</b></td>
                                <td>{getPaymentMethod(orderDetail?.order_paygate)}</td>
                            </tr>
                            <tr>
                                <td><b>Status:</b></td>
                                <td>{orderDetail?.status == 2 ? (<span className='badge badge-danger text-bold'>Cancel</span>) : (<span className='badge badge-success text-bold'>Complete</span>)}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-12 col-md-6">
                        <table className="table table-not-mobile">
                            {
                                orderDetail?.cdkey ? (
                                    <tr>
                                        <td><b>CDkey:</b></td>
                                        <td>{orderDetail?.cdkey}</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td><b>Date Expired:</b></td>
                                        <td>{orderDetail?.order_plan_id != null && orderDetail?.plan_type != 'buy_credit' ? orderDetail?.date_expired : ''}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td><b>Description:</b></td>
                                <td>{orderDetail?.description}</td>
                            </tr>
                            <tr>
                                <td><b>Date:</b></td>
                                <td>{orderDetail?.date}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default OrdersList;
