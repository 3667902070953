import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Modal, Spin, notification } from 'antd';
import { partner } from '../../../endpoint';

import logo from '../../../assets/images/shopsocks5.png';

const Footers = () => {
    const d = new Date();
    const year = d.getFullYear();
    const [loading, setLoading] = useState(true);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [data, setData] = useState([]);
    const [dataLists, setDataLists] = useState([]);

    /**
     * Handle list partner
     */
    const loadListPartner = () => {
        setLoading(true);
        const parameters = {'category_id': 'all'};
        partner.getListFrontend(parameters).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            setLoading(false);
        });
    }

    /**
     * Handle cancel modal
     */
    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    /**
     * Handle show modal partner
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setData(record);
        setIsModalVisibleAds(true);
    };
    
    /**
     * Handle ok redirect
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(data.url, '_blank', 'noopener,noreferrer');
    }

    useEffect(() => {
        loadListPartner();
    }, []);

    return (
        <footer>
            <div className="footer-top">
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-sm-4 col-md-20-5">
                            <Link className="navbar-brand" to="/">
                                <img src={logo} />
                            </Link>
                            <p className='mb-1'>Skype: {window.confirm?.skype}</p>
                            <p className='mb-1'>Email: {window.confirm?.support_email}</p>
                            <p className='mb-1'>Website: <a href='https://shopsocks5.com/feedback.html' className='text-white'>Application Assistance</a></p>
                        </div>
                        <div className="col-12 col-sm-4 col-md-20-5">
                            <div className='footer-widget'>
                                <h3>Top Category</h3>
                                <ul>
                                    <li><a href='https://shopsocks5.com/'><i className="fa fa-angle-double-right"></i> Home</a></li>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html'><i className="fa fa-angle-double-right"></i> Pricing</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/how-to-use-shopsocks5-socks5-client'><i className="fa fa-angle-double-right"></i> Software</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/api'><i className="fa fa-angle-double-right"></i> API</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/Become-our-partner'><i className="fa fa-angle-double-right"></i> Become our partner</a></li>
                                    <li><a href='https://shopsocks5.com/user-invite.html'><i className="fa fa-angle-double-right"></i> Affiliate program</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-20-5">
                            <div className='footer-widget'>
                                <h3>Socks5 Proxies</h3>
                                <ul>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html?tab=socks5_premium'><i className="fa fa-angle-double-right"></i> ISP Socks5</a></li>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html?tab=socks5_daily'><i className="fa fa-angle-double-right"></i> Socks5 Daily</a></li>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html?tab=socks5_list'><i className="fa fa-angle-double-right"></i> Socks5 List</a></li>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html?tab=static_proxies'><i className="fa fa-angle-double-right"></i> Static Datacenter Proxies</a></li>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html?tab=cloud_vps'><i className="fa fa-angle-double-right"></i> Cloud VPS</a></li>
                                    <li><a href='https://shopsocks5.com/buy-socks5.html?tab=s5'><i className="fa fa-angle-double-right"></i> Reseller S5Proxy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-20-5">
                            <div className='footer-widget'>
                                <h3>Help Center</h3>
                                <ul>
                                    <li><a href='https://shopsocks5.com/faq-detail/faq'><i className="fa fa-angle-double-right"></i> FAQS</a></li>
                                    <li><a href='https://shopsocks5.com/faqs.html'><i className="fa fa-angle-double-right"></i> User Guide</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-20-5">
                            <div className='footer-widget'>
                                <h3>Service and Terms</h3>
                                <ul>
                                    <li><a href='https://shopsocks5.com/faq-detail/About_Us'><i className="fa fa-angle-double-right"></i> About us</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/Contact_Us'><i className="fa fa-angle-double-right"></i> Contact us</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/Privacy_Policy'><i className="fa fa-angle-double-right"></i> Privacy Policy</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/Cookie_Policy'><i className="fa fa-angle-double-right"></i> Cookie Policy</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy'><i className="fa fa-angle-double-right"></i> Terms of Service</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/Delivery_Agreement'><i className="fa fa-angle-double-right"></i> Delivery Agreement</a></li>
                                    <li><a href='https://shopsocks5.com/faq-detail/Refund_Policy_and_Dispute_Resolution'><i className="fa fa-angle-double-right"></i> Refund Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='fp_line'></div>
                </div>
            </div>
            <div className='footer-partners'>
                <div className="container">
                    {
                        loading ? (
                            <div className="text-center"><Spin /></div>
                        ) : (
                            <div className='row'>
                                <div className="col-12 col-sm-12">
                                    <h4>Partnership</h4>
                                    <ul>
                                        {
                                            dataLists && dataLists.length > 0 && dataLists.map((item, key) => 
                                                <li key={item.id}>
                                                    <a onClick={() => { handleShowModalAds(item) }} >{item?.name}</a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    <div className='fp_line'></div>
                </div>
            </div>
            <div className="footer-content">
                <div className="container">
                    <div className='row'>
                        <div className="col-md-12">
                            <p className="remove-margin text-center">Copyright © 2023 - <a href="https://shopsocks5.com/" className="copyright">Shopsocks5.com</a> | All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line">
                            Dear customer,<br/><br/>
                            If you wish to access this website please note that we are not the owners of this website.<br/><br/>
                            Any purchase or sale between you and this website is not related to us<br/><br/>
                            By clicking 'OK' you will be redirected to this website.<br/><br/>
                            Please contact them before proceeding with the transaction.<br/><br/>
                            Thank you!
                        </p>
                    </div>
                </div>
            </Modal>
        </footer>
    );
}
export default Footers;
