import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, CommentOutlined } from '@ant-design/icons';
import { Button, message, Input, Drawer, notification, Popconfirm, Form, AutoComplete } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormDateTimePicker, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { orders, plan, users, authenticator } from '../../../../endpoint';
import { getParamsByUrl, constants } from '../../../../_until';

const { Option } = AutoComplete;

/**
 * Add node
 *
 * @param fields
 * @param userID
 */
const handleAdd = async (fields, userID) => {
    const hide = message.loading('Processing...');
    try {
        var res = await orders.addItem({ ...fields, 'order_userid': userID});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Add Note
 *
 * @param fields
 * @param order_id
 */
const handleAddNote = async (fields, order_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await orders.addNote({ ...fields, 'order_id': order_id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: res.message,
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Add Description
 *
 * @param fields
 * @param order_id
 */
const handleAddDescription = async (fields, order_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await orders.addDescription({ ...fields, 'order_id': order_id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: res.message,
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await orders.deleteItems({
            lists: selectedRows.map((row) => row.order_id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Add Cancel Order
 *
 * @param fields
 * @param order_id
 */
const handleCancelOrder = async (order_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await orders.cancelOrder({'order_id': order_id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: res.message,
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Cancel failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const OrderComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [createModalVisibleNote, handleModalVisibleNote] = useState(false);
    const [createModalVisibleDescription, handleModalVisibleDescription] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const [formNote] = Form.useForm();
    const [formDescription] = Form.useForm();
    const [value, setValue] = useState("");
    const [options, setOptions] = useState([]);
    const [userID, setUserID] = useState(0);
    const [orderId, setOrderId] = useState(0);
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, [value, userID]);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await orders.getList(parameters);
        return data;
    }

    /**
     * Load list plan select
     */
    const plans = async () => {
        const data = await plan.getSelect();
        return data;
    }

    /**
     * Load list user select
     */
    const user = async (parameters) => {
        const data = await users.getList(parameters);
        return data != null ? data : {};
    }

    /**
     * Load list search user select
     *
     * @param searchText
     */
    const onSearch = async (searchText) => {
        const users = await user({"user_name": searchText});
        setOptions(users.data);
    };

    /**
     * Handle select item
     *
     * @param data
     * @param record
     */
    const onSelect = (data, record) => {
        setUserID(record.key);
        setValue(data);
    };

    /**
     * Handle onchange item
     *
     * @param user_name
     * @param record
     */
    const onChange = (user_name, record) => {
        setUserID(record.key);
        setValue(user_name);
    };

    /**
     * Show payment method
     *
     * @param type
     */
    const renderPaymentMethod = (type) => {
        if (type.toLowerCase() == constants.PerfectMoney.toLowerCase()) {
            return 'Perfect Money'
        } else if (type.toLowerCase() == constants.WebMoney.toLowerCase()) {
            return 'WebMoney';
        } else if (type.toLowerCase() == constants.Bitcoin.toLowerCase()) {
            return 'Bitcoin (BTC)';
        } else if (type.toLowerCase() == constants.BitcoinCash.toLowerCase()) {
            return 'Bitcoin Cash (BCH)';
        } else if (type.toLowerCase() == constants.ERC20.toLowerCase()) {
            return 'USDT (ERC20)';
        } else if (type.toLowerCase() == constants.TRC20.toLowerCase()) {
            return 'USDT (TRC20)';
        } else if (type.toLowerCase() == constants.BSC.toLowerCase()) {//'bep20'
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else if (type.toLowerCase() == constants.Credit.toLowerCase()) {
            return 'SS5 Point';
        } else if (type.toLowerCase() == constants.Payeer.toLowerCase()) {
            return 'Payeer';
        } else if (type.toLowerCase() == constants.Litecoin.toLowerCase()) {
            return 'Litecoin';
        } else {
            return 'Other';
        }
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Order ID",
            dataIndex: 'order_id',
            sorter: false,
            hideInSearch: true,
            valueType: 'text',
            initialValue: paramQuery?.plan_name,
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">#{dom}</span>
                    </a>
                );
            }
        },
        {
            title:'User',
            dataIndex: 'user',
            hideInSearch: true,
        },
        {
            title:'Plan',
            dataIndex: 'plan',
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.order_id}>{record.plan != null ? record?.plan : record?.package}</span>
            ]
        },
        {
            title:'Method',
            dataIndex: 'order_paygate',
            hideInSearch: false,
            valueEnum: {
                [constants.PerfectMoney.toLowerCase()]: {
                    text:'Perfect Money',
                },
                [constants.WebMoney.toLowerCase()]: {
                    text: "WebMoney"
                },
                [constants.Bitcoin.toLowerCase()]: {
                    text: "Bitcoin (BTC)"
                },
                [constants.BitcoinCash.toLowerCase()]: {
                    text: "Bitcoin Cash (BCH)"
                },
                [constants.Litecoin.toLowerCase()]: {
                    text: "Litecoin"
                },
                [constants.USDT.toLowerCase()]: {
                    text: "USDT"
                },
                [constants.Credit.toLowerCase()]: {
                    text: "SS5 Point"
                },
                [constants.Payeer.toLowerCase()]: {
                    text: "Payeer"
                }
            },
            render: (dom, item) => {
                return (
                    <span>{renderPaymentMethod(item.order_paygate)}</span>
                );
            }
        },
        {
            title:'Amount',
            dataIndex: 'order_amount',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'order_amount' + record.order_id}>${record.order_paygate != 'credit' ? record.order_amount : (record.order_amount/10).toFixed(2)}</span>
            ]
        },
        {
            title:'Status',
            dataIndex: 'status',
            valueEnum: {
                0: {
                    text:'Pending',
                    status: 'Default',
                },
                1: {
                    text: "Complete",
                    status: 'Success',
                },
                2: {
                    text: "Refund",
                    status: 'red',
                }
            },
        },
        {
            title:'Date Expired',
            dataIndex: 'date_expired',
            hideInSearch: true,
        },
        {
            title:'Note',
            dataIndex: 'note',
            hideInSearch: true,
        },
        {
            title:'Description',
            dataIndex: 'description',
            hideInSearch: true,
        },
        {
            title:'Transaction ID',
            dataIndex: 'transaction_id',
            hideInSearch: true,
            maxWidth: 120,
            render: (_, record) => [
                <span key={"transaction_id" +record.order_id} style={{maxWidth: "120px", display: "inline-block"}}>{record.transaction_id}</span>
            ]
        },
        {
            title:'Cost',
            dataIndex: 'cost',
            hideInSearch: true,
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="note" onClick={() => { setOrderId(record.order_id); handleModalVisibleNote(true); }} className="btn btn-success text-white"><CommentOutlined /> Note</a>,
                <a key="description" onClick={() => { setOrderId(record.order_id); handleModalVisibleDescription(true); }} className="btn btn-success text-white"><CommentOutlined /> Description</a>,
                <Popconfirm
                    key={"cancel" + record.id}
                    title="Are you sure to cancel this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleCancelOrder(record.order_id);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><EditOutlined />  Cancel</a>
                </Popconfirm>,
                <Popconfirm
                    key={"delete" + record.order_id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><DeleteOutlined />  Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Orders Manager"
                actionRef={actionRef}
                request={list}
                rowKey="order_id"
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>,
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 100)
                }}
            />

             {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
                </FooterToolbar>
            )}

            {/*Form Add new*/}
            <ModalForm
                id="form-add-order"
                title="Add Order"
                width="500px"
                form={form}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    if (typeof userID == 'undefined' || userID == 0) {
                        notification.open({
                            message: 'Fail!',
                            description: 'User not found!',
                            type: 'error'
                        });
                    } else {
                        const success = await handleAdd(value, userID);
                        if (success) {
                            form.resetFields();
                            handleModalVisible(false);
                            if (actionRef.current) {
                                actionRef.current.reload();
                            }
                        }
                    }
                }}
            >
                <div className="ant-form-item-label">
                    <label className="ant-form-item-required">User</label>
                </div>
                <AutoComplete
                    value={value}
                    required={true} 
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select a User!' 
                        }
                    ]}
                    style={{ width: "calc(100% - 14px)", "maxWidth": '100%', "marginBottom": 15}}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onChange={onChange}
                >
                    {options && options.map((record) => (
                        <Option key={record.user_id} value={record.user_name} label={record.user_name}>
                            {record.user_name}
                        </Option>
                    ))}
                </AutoComplete>

                <ProFormSelect
                    width="lg"
                    name="order_plan_id"
                    label="Plan"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={plans}
                    placeholder="Please select a Plan"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select a Plan!' 
                        }
                    ]}
                />
                <ProFormSelect
                    width="lg"
                    name="order_paygate"
                    label="Type"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Perfect Money', value: constants.PerfectMoney.toLowerCase() },
                        { label: 'WebMoney', value: constants.WebMoney.toLowerCase() },
                        { label: 'BitCoin', value: constants.Bitcoin.toLowerCase() },
                        { label: 'Bitcoin Cash (BCH)', value: constants.BitcoinCash.toLowerCase() },
                        { label: 'USDT (ERC20)', value: constants.ERC20.toLowerCase() },
                        { label: 'USDT (TRC20)', value: constants.TRC20.toLowerCase() },
                        { label: 'USDT BSC (BNB Smart Chain(BEP20))', value: constants.BSC.toLowerCase() },
                        { label: 'Payeer', value: constants.Payeer.toLowerCase() }
                    ]}
                    placeholder="Please select a type"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select type!' 
                        }
                    ]}
                />
                <ProFormDateTimePicker 
                    label="Date: " 
                    width="lg"
                    name="order_time"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select Date!' 
                        }
                    ]}
                />
            </ModalForm>

            {/*Form Add Note*/}
            <ModalForm
                id="form-add-note"
                title="Add Note"
                width="500px"
                form={formNote}
                visible={createModalVisibleNote}
                onVisibleChange={handleModalVisibleNote}
                onFinish={async (value) => {
                    const success = await handleAddNote(value, orderId);
                    if (success) {
                        formNote.resetFields();
                        handleModalVisibleNote(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >    
                <Form.Item name="note"  width="lg" label="Note" rules={[{ required: true }]}>
                    <Input.TextArea rows="6" />
                </Form.Item>
            </ModalForm>

            {/*Form Add Description*/}
            <ModalForm
                id="form-add-description"
                title="Add Description"
                width="500px"
                form={formDescription}
                visible={createModalVisibleDescription}
                onVisibleChange={handleModalVisibleDescription}
                onFinish={async (value) => {
                    const success = await handleAddDescription(value, orderId);
                    if (success) {
                        formDescription.resetFields();
                        handleModalVisibleDescription(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >    
                <Form.Item name="description"  width="lg" label="Description" rules={[{ required: true }]}>
                    <Input.TextArea rows="6" />
                </Form.Item>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.order_id && (
                    <div>
                        <ProDescriptions 
                            title={currentRow?.order_id}
                            request={async () => ({
                                data: currentRow || {},
                            })}
                            params={{
                                id: currentRow?.order_id,
                            }}
                            columns={columns}
                        />
                        <div className='confirm-content'>
                            <p><strong>Confirm Content</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: currentRow?.confirm_content }}></div>
                        </div>
                    </div>
                )}
                
            </Drawer>
        </PageContainer>
    );
}
export default OrderComponent;
