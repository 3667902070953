import { Request } from '../_until';

export const payment = {
    get_confirm,
    payment_point,
    config_payment,
    check_payment,
    tracking_confirm,
    check_status_history
};

const urls = {
    get_confirm: `payment/get_confirm`,
    payment_point: `payment/payment_point`,
    config_payment: `payment/config_payment`,
    check_payment: `payment/check_confirm`,
    tracking_confirm: `payment/tracking_confirm`,
    check_status_history: `payment/check_status_history`
};

function get_confirm(requestOptions) {
    return Request.post_website(urls.get_confirm, requestOptions).then(res => res.data);
};

function payment_point(requestOptions) {
    return Request.post_website(urls.payment_point, requestOptions).then(res => res.data);
};

function config_payment(requestOptions) {
    return Request.post_website(urls.config_payment, requestOptions).then(res => res.data);
};

function check_payment(requestOptions) {
    return Request.post_website(urls.check_payment, requestOptions).then(res => res.data);
};

function tracking_confirm(requestOptions) {
    return Request.post_website(urls.tracking_confirm, requestOptions).then(res => res.data);
};

function check_status_history(requestOptions) {
    return Request.post_website(urls.check_status_history, requestOptions).then(res => res.data);
};