import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

const S5Item = ({item, image}) => {
    useEffect(() => {
    }, [item, image]);

    return (
        <div className="package-s5">
            <div className="package-side package-side-left">
                <div className="package-header">
                    <div className="package-title text-center">
                        <img src={image} width="135" />
                    </div>
                    <div className="package-price">
                        <div className="price">
                            <div className="price-starting-from">
                                <h4><b>{item.plan_name}</b></h4>
                            </div>
                            <div className="price-amount">{item.plan_price.toFixed(2)}$</div>
                        </div>
                    </div>
                </div>
                <div className="package-body">
                    <div className="package-content">
                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                    </div>
                </div>
            </div>
            <div className="package-footer package-side package-side-right">
                <Link to={"/payment/" + item.id + "?type_product=s5"} className="btn btn-primary btn-order-now btn-block">
                    <i className="fa fa-registered" aria-hidden="true"></i> Buy Now
                </Link>
            </div>
        </div>
    )
}
export default S5Item;
