import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Spin, Checkbox, Radio, Form, notification, Button, Input, Modal } from 'antd';
import { account, banner, socks } from '../../endpoint';

const Checksock = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [dataBanner, setBanner] = useState([]);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [profile, setProfile] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Check Socks5 - " + window?.title;
        if (user != null) {
            getProfile();
            banner.get_list_banner({position: 'banner_top'}).then(res => {
                if (res.status == 'success') {
                    setBanner(res.response);
                }
            }).then(complete => {
                setLoading(false);
            }).then(error => {
                setLoading(false);
            });
        }
    }, []);

    /**
     * Handle get info profile
     */
    const getProfile = () => {
        setLoadingProfile(false);
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
            }
            setLoadingProfile(true);
        });
    }

    /**
     * Handle close modal ads
     */
    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    /**
     * Handle redirect to link
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    /**
     * Function fill sock
     *
     * @param listSock
     */
    const fillSock = (listSock) => {
        var fuckTemp = [];
        const regexDomainPort = /^([^:]+):(\d+):([^:]+):(.+)$/;
        const regexSock = /(\d{1,3}(?:\.\d{1,3}){3}):(\d+)/;
        if (typeof listSock != 'undefined' && listSock != '') {
            let fuck = listSock.split("\n").map(item => item.trim()).filter(item => item !== "");
            if (fuck != null) {
                for (var i = 0; i < fuck.length; i++) {
                    var item = fuck[i].match(regexDomainPort);
                    if (item == null || item.length != 5) {
                        item = fuck[i].match(regexSock);
                    }
                    if (item) {
                        if ($.inArray(fuck[i], fuckTemp) === -1) fuckTemp.push(fuck[i]);
                    }
                }
                var list = "";
                fuck = fuckTemp;
                for (var i = 0; i < fuck.length; i++) {
                    list = list + fuck[i];
                    if (i != (fuck.length-1)) {
                        list = list + "\n";
                    }
                }
                $('textarea#nest-messages_list_sock').val(list);
                form.setFieldsValue({list_sock: list});
            }
        }
        return fuckTemp;
    }

    /**
     * Handle check sock
     *
     * @param listSock
     * @param type
     */
    const handleCheckSock = (listSock, type) => {
        var checked = 0;
        var die = 0;
        var live = 0;
        $('#result-check').addClass('d-none');
        $("#sock-result .count-check").text(checked);
        $("#sock-result .count-live").text(live);
        $("#sock-result .count-die").text(die);
        $("#die").html('');
        $("#live").html('');
        $('.icon-check').show();
        $('#result-check').removeClass('d-none');
        $('#result-check .count-all').html(listSock.length);
        try {
            $('#nest-messages .loading-form').show();
            form.resetFields();
            var total = listSock.length;
            for (var i = 0; i < listSock.length; i++) {
                var sock = listSock[i];
                socks.checkSock({'sock': sock, 'type': type}).then((res) => {
                    checked++;
                    $("#sock-result .count-check").text(checked);
                    if (checked == total) {
                        $('#nest-messages .loading-form').hide();
                    }
    
                    if (res.status == "success") {
                        live++;
                        $("#sock-result .count-live").text(live);
                        $("#live").append(res.message);
                    } else if (res.status == 'fail') {
                        die++;
                        $("#sock-result .count-die").text(die);
                        $("#die").append(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        } catch (error) {
            $('#nest-messages .loading-form').hide();
        }
        return false;
    };

    /**
     * Handle check request
     *
     * @param fields
     */
    const handleCheck = (fields) => {
        const list_sock = fillSock(fields.list_sock);
        if (list_sock.length > 100) {
            notification.open({
                message: 'Fail!',
                description: "Please check under 100 socks5!",
                type: 'error'
            });
        } else {
            if (list_sock.length > 0) {
                socks.historySock({'list_sock': list_sock}).then((res) => {
                    if (res.status == 'success') {
                        handleCheckSock(list_sock, fields?.type);
                    } else {
                        if (typeof res.message != 'undefined') {
                            notification.open({
                                message: 'Fail!',
                                description: res.message,
                                type: 'error'
                            });
                        }
                    }
                }).then(com => {
                }).then(err => {
                });
            } else {
                notification.open({
                    message: 'Fail!',
                    description: "Please enter list socks5!",
                    type: 'error'
                });
            }
        }
    }

    /**
     * Handle fill sock
     */
    const handleFillsock = () => {
        var val = $('textarea#nest-messages_list_sock').val();
        fillSock(val);
    }

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
            {
                profile?.plan_type != 'None' && loadingProfile && (
                    <>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                                <div className={(loading ? " d-none" : "")}>
                                    <div className="servicer">
                                        <div className="row align-items-center">
                                            {
                                                dataBanner && dataBanner.length > 0 && dataBanner.map((item, key) => 
                                                    <div className={"col-12 col-sm-" + (item.is_full != 0 ? (12/item.is_full) : '12')} key={item.id}>
                                                        <a onClick={()=> handleShowModalAds(item)}>
                                                            <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-body" style={{padding: "15px 20px", backgroundColor: "#f5f5f5", borderColor: "#ddd"}}>
                                        <h2 className="text-center mb-4 mt-2">Check Socks5 Online</h2>
                                        <p className='mb-3'>
                                            <b>Format of the proxies to be added to the proxy checker</b><br/>
                                            <b>IP:PORT</b> - if you have public proxies (without login and password)<br/>
                                            <b>IP:PORT:USER:PASS</b> - if you have private proxies (authorized by login and password)
                                        </p>
                                        <Form name="nest-messages" form={form} layout="vertical" className="mt-3" onFinish={handleCheck}>
                                            <div className="relative">
                                                <Form.Item name="list_sock" width="lg" label="" rules={[{ required: true }]}>
                                                    <Input.TextArea rows="6" />
                                                </Form.Item>
                                                <Form.Item
                                                    labelCol={{span: 24}}
                                                    name="type"
                                                    initialValue='proxy_sock_5'
                                                    className='text-center'
                                                >
                                                    <Radio.Group>
                                                        <Radio value='proxy_sock_5'>SOCKS5</Radio>
                                                        <Radio value='proxy_https'>HTTPS</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item className="text-center d-none">
                                                    <Checkbox name="blacklist">Check Blacklist</Checkbox>
                                                </Form.Item>
                                                <Form.Item className="text-center mt-4">
                                                    <Button htmlType="button" className="mr-2 d-none" onClick={handleFillsock}>Fill Socks5</Button>
                                                    <Button type="primary" htmlType="submit">Check Socks5</Button>
                                                </Form.Item>
                                                <div className="loading-form"><div><Spin /></div></div>
                                            </div>
                                            <div id="result-check" className="d-none">
                                                <div id="sock-result" className="text-center">
                                                    All: <font color="violet" className="count-all">0</font>&nbsp;
                                                    | Checked: <font color="green" className="count-check">0</font>&nbsp;
                                                    | Success: <font color="blue" className="count-live">0</font>&nbsp;
                                                    | Error: <font color="red" className="count-die">0</font>
                                                </div>
                                                <hr />
                                                <div id="live" className="mb-4"></div>
                                                <div id="die"></div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                        <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                                </div>
                            </div>
                        </Modal>
                    </>
                )
            }
            {
                profile?.plan_type == "None" && loadingProfile && (
                    <div className="alert alert-danger text-center" style={{maxWidth: "800px", margin: "15px auto"}}>Only customers of shopsocks5.com can use checker socks5</div>
                ) 
            }
        </div>
    );
}
export default Checksock;
