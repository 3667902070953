import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, notification, Button, message } from 'antd';
import { account } from '../../../endpoint';
import DailyItem from './DailyItem';

const DailyList = ({listData, profile, isShowTrial}) => {
    const history = useHistory();
    const [isModalVisibleTrial, setIsModalVisibleTrial] = useState(false);
    const [typeTrial, setTypeTrial] = useState("");
    const user = JSON.parse(localStorage.getItem('user'));

    /**
     * Show button trial
     *
     * @param type
     */
    function getButtonTrial(type) {
        return (
            <a className="btn-continue-google" onClick={() => {
                if (user != null) {
                    setTypeTrial(type);
                    setIsModalVisibleTrial(true);
                } else {
                    history.push({pathname: '/login.html'});
                    return;
                }
            }}>Continue</a>
        )
    }

    /**
     * Handle register trial
     */
    const handleTrial = () => {
        const hide = message.loading('Processing...');
        setIsModalVisibleTrial(false);
        account.check_trial({type: typeTrial}).then(res => {
            if (res.status == 'success') {
                location.href = window.base_url + 'google-redirect/' + res.trial_id;
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Trial registration failed.',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(complete => {
            hide();
        }).then(err => {
        });
    }

    useEffect(() => {
    }, [listData, profile, isShowTrial]);

    return (
        <>
            <div className="plan-package-box ips-box residential__plan_box">
                <div className="plan-package-setting">
                    <div className="setting-top">
                        <div className="setting-box residential__setting">
                            <div className="set-tit">Select the plan that your requirements</div>
                            <div className="set-change-plan d-none">
                                <span className="ips-popular setting-tit residential__setting_tit residential__popular setting__onswitch">Popular Plan</span>
                                <div className="layui-form"></div>
                                <span className="ips-enterprise setting-tit residential__setting_tit residential__enterprise">Enterprise Plan</span>
                            </div>
                        </div>
                    </div>
                    <div className="setting-adv">
                        <div className="setting-item">Expiry date</div>
                        <div className="setting-item">Daily limit</div>
                        <div className="setting-item">Total Socks5 Receive</div>
                        <div className="setting-item">Reset Limit everyday</div>
                        <div className="setting-item">Country selection</div>
                        <div className="setting-item">Get List socks5</div>
                        <div className="setting-item setting-item-btn"></div>
                    </div>
                </div>
                <div className="plan-package-content residential__package residential__package_popular">
                    {
                        isShowTrial && (
                            <div className="plan-package-item plan__package_item">
                                <div className="package-item-content">
                                    <div className="package-detail">
                                        <div className="package-num">
                                            <span className="package-number">
                                                <span className="residential__num">DAILY 5 SOCKS5 1 DAY</span>
                                            </span>
                                        </div>
                                        <div className="package-price mt-2">
                                            <span>$</span>
                                            <span className="residential__total">0</span>
                                            <span className="package-original-price d-none">
                                                <span>$</span>
                                                <span className="residential__original"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="package-adv">
                                        <div className="adv-item">
                                            <div className="day">1 Days</div>
                                        </div>
                                        <div className="adv-item">
                                            <span className="adv-item-tit">Expiry date</span>
                                            <span className="adv-item-detail">
                                                <span className="residential__all_flow">1 Day</span>
                                            </span>
                                        </div>
                                        <div className="adv-item">
                                            <span className="adv-item-tit">Daily limit</span>
                                            <span className="adv-item-detail">
                                                <span>5</span>
                                            </span>
                                        </div>
                                        <div className="adv-item">
                                            <span className="adv-item-tit">Total Socks5 Receive</span>
                                            <span className="adv-item-detail">
                                                <span>5</span>
                                            </span>
                                        </div>
                                        <div className="adv-item">
                                            <span className="adv-item-tit">Reset Limit everyday</span>
                                            <span className="adv-item-detail daily-list-socks5">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="16" height="20" viewBox="0 0 64.000000 71.000000" preserveAspectRatio="xMidYMid meet">
                                                        <g transform="translate(0.000000,71.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                            <path d="M275 696 c-53 -13 -90 -30 -115 -52 -8 -7 -32 -28 -52 -45 -21 -17 -35 -35 -32 -40 3 -5 1 -9 -5 -9 -6 0 -21 -19 -33 -42 -19 -35 -23 -59 -23 -128 0 -91 10 -120 67 -192 26 -34 28 -40 18 -80 -5 -24 -10 -53 -10 -65 0 -22 1 -22 58 9 50 27 68 31 142 32 65 1 96 7 132 24 58 27 121 81 114 97 -3 8 2 11 15 8 11 -3 19 0 19 6 0 7 9 24 20 39 11 15 26 52 34 83 42 165 -80 333 -264 362 -19 3 -57 0 -85 -7z m-37 -169 c7 -9 18 -14 24 -11 6 4 8 3 5 -3 -4 -6 4 -20 16 -31 l22 -21 38 35 c20 20 35 39 33 42 -2 4 8 7 24 7 17 0 26 -4 23 -12 -5 -14 19 -43 36 -43 22 0 10 -25 -31 -67 l-42 -43 39 -40 c21 -21 42 -37 48 -33 19 11 -12 -33 -45 -65 l-34 -32 -45 45 -44 44 -45 -44 -46 -45 -39 40 -40 41 47 43 46 44 -44 41 c-48 44 -48 56 -5 95 11 10 18 22 16 26 -10 16 31 4 43 -13z"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="adv-item">
                                            <span className="adv-item-tit">Country selection</span>
                                            <span className="adv-item-detail">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="adv-item">
                                            <span className="adv-item-tit">Get List socks5</span>
                                            <span className="adv-item-detail daily-list-socks5">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="16" height="20" viewBox="0 0 64.000000 71.000000" preserveAspectRatio="xMidYMid meet">
                                                        <g transform="translate(0.000000,71.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                            <path d="M275 696 c-53 -13 -90 -30 -115 -52 -8 -7 -32 -28 -52 -45 -21 -17 -35 -35 -32 -40 3 -5 1 -9 -5 -9 -6 0 -21 -19 -33 -42 -19 -35 -23 -59 -23 -128 0 -91 10 -120 67 -192 26 -34 28 -40 18 -80 -5 -24 -10 -53 -10 -65 0 -22 1 -22 58 9 50 27 68 31 142 32 65 1 96 7 132 24 58 27 121 81 114 97 -3 8 2 11 15 8 11 -3 19 0 19 6 0 7 9 24 20 39 11 15 26 52 34 83 42 165 -80 333 -264 362 -19 3 -57 0 -85 -7z m-37 -169 c7 -9 18 -14 24 -11 6 4 8 3 5 -3 -4 -6 4 -20 16 -31 l22 -21 38 35 c20 20 35 39 33 42 -2 4 8 7 24 7 17 0 26 -4 23 -12 -5 -14 19 -43 36 -43 22 0 10 -25 -31 -67 l-42 -43 39 -40 c21 -21 42 -37 48 -33 19 11 -12 -33 -45 -65 l-34 -32 -45 45 -44 44 -45 -44 -46 -45 -39 40 -40 41 47 43 46 44 -44 41 c-48 44 -48 56 -5 95 11 10 18 22 16 26 -10 16 31 4 43 -13z"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="package-btn">
                                        { getButtonTrial('Daily') }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        listData && listData.length > 0 && listData.map((item, key) => 
                            item.plan_expired == 30 && (
                                <div className={"plan-package-item plan__package_item" + (item?.is_feature == 1 ? ' package__active' : '')} key={item.plan_id}>
                                    <DailyItem item={item} listData={listData} profile={profile} />
                                </div>
                            )
                        )
                    }
                </div>
            </div>
            <Modal visible={isModalVisibleTrial} footer={null} onCancel={() => {setIsModalVisibleTrial(false)}} width={480}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className='mb-1'>We offer a package free trial.</p>
                        <p className='mb-1'>You plz connect account google.</p>
                        <p className='mb-1'>My site will activate your account package trial</p>
                        <p className='mb-1 text-danger'>We only support personal emails. @gmail email address</p>
                        <p>Thank you very much</p>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleTrial} className="d-block medium">Continue</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={() => {setIsModalVisibleTrial(false)}} className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default DailyList;
