import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Button} from 'antd';
import mostPopular from '../../../assets/theme/images/most_popular.png';
import CountdownTimer from './CountdownTimer';

const PremiumList = ({listData, profile}) => {
    const history = useHistory();
    const [currentPlan, setCurrentPlan] = useState({});
    const [isModalVisiblePlan, setIsModalVisiblePlan] = useState(false);

    useEffect(() => {
    }, [listData, profile]);

    /**
     * Check buy another
     * 
     * @param item
     */
    const checkBuyAnother = (item) => {
        if (profile?.plan_type != "None" && !(profile?.plan_type == currentPlan.plan_type && profile?.plan_daily_limit == currentPlan.plan_daily_limit)) {
            setCurrentPlan(item);
            setIsModalVisiblePlan(true);
        } else {
            history.push({pathname: '/payment/' + item?.plan_id});
        }
    }

    /**
     * Handle buy another
     */
    const handleBuyAnother = () => {
        setIsModalVisiblePlan(false);
        history.push({pathname: '/payment/' + currentPlan?.plan_id});
    }

    /**
     * Handle calculater discount
     *
     * @param item
     */
    const handledCalculaterDiscount = (item) => {
        if (item?.discount) {
            return item?.plan_price - ((item?.plan_price * item?.discount.discount)/100);
        }
        return item?.plan_price;
    }

    return (
        <div className="plan-package-box ips-box residential__plan_box">
            <div className="plan-package-setting">
                <div className="setting-top">
                    <div className="setting-box residential__setting">
                        <div className="set-tit">Select the plan that your requirements</div>
                        <div className="set-change-plan d-none">
                            <span className="ips-popular setting-tit residential__setting_tit residential__popular setting__onswitch">Popular Plan</span>
                            <div className="layui-form"></div>
                            <span className="ips-enterprise setting-tit residential__setting_tit residential__enterprise">Enterprise Plan</span>
                        </div>
                    </div>
                </div>
                <div className="setting-adv">
                    <div className="setting-item">Traffic</div>
                    <div className="setting-item">IP amount</div>
                    <div className="setting-item">Country selection</div>
                    <div className="setting-item">
                        <span data-lang="pacakge_setting8">Shared IP</span>
                    </div>
                    <div className="setting-item">Expiry Date</div>
                    <div className="setting-item">Expiry Credit</div>
                    <div className="setting-item setting-item-btn"></div>
                </div>
            </div>
            <div className="plan-package-content residential__package residential__package_popular">
                {
                    listData && listData.length > 0 && listData.map((item, key) => 
                        <div className={"plan-package-item plan__package_item" + (item?.is_feature == 1 ? ' package__active' : '')} key={item.plan_id}>
                            {
                                item?.is_feature == 1 && (
                                    <div className="activity-img-right popular__activity_img">
                                        <img src={mostPopular} width="112px" height="43px" />
                                    </div>
                                )
                            }
                            <div className="package-item-content">
                                <div className="package-detail">
                                    <div className="package-num">
                                        <span className="package-number">
                                            <span className="residential__num">{item.plan_name}</span>
                                        </span>
                                    </div>
                                    <div className="package-price mt-2">
                                        <span>$</span>
                                        <span className="residential__total">{handledCalculaterDiscount(item)}</span>
                                        {
                                            item?.discount ? (
                                                <span className="package-original-price ml-1 text-danger">
                                                    <span>$</span>
                                                    <span className="residential__original">{item.plan_price}</span>
                                                </span>
                                            ) : ""
                                        }
                                    </div>
                                </div>
                                <div className="package-adv">
                                    <div className="adv-item">
                                        <span className="adv-item-detail">
                                            <span className="residential__all_flow">{item?.total_receive > 0 ? '$' + parseFloat(item.plan_price/item.total_receive).toFixed(2) + '/IP' : ''}</span>
                                        </span>
                                    </div>
                                    <div className="adv-item">
                                        <span className="adv-item-tit">Traffic</span>
                                        <span className="adv-item-detail">
                                            <span className="residential__all_flow">Unlimited</span>
                                        </span>
                                    </div>
                                    <div className="adv-item">
                                        <span className="adv-item-tit">IP amount</span>
                                        <span className="adv-item-detail">
                                            <span>{item?.total_receive}</span>
                                        </span>
                                    </div>
                                    <div className="adv-item">
                                        <span className="adv-item-tit">Country selection</span>
                                        <span className="adv-item-detail">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="adv-item">
                                        <span className="adv-item-tit">Shared IP</span>
                                        <span className="adv-item-detail">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.24771e-06 7.00838C-0.00468277 3.1486 3.13224 0.00466108 6.99162 5.17617e-06H6.99161C10.8514 -0.00465073 13.9953 3.13224 14 6.99162C14.0047 10.8514 10.8678 13.9953 7.00838 14C3.1486 14.0047 0.00466116 10.8677 5.24771e-06 7.00838ZM6.06808 9.91479L11.3418 4.62889V4.62888C11.4931 4.47721 11.4926 4.23221 11.3414 4.08147C11.1897 3.93027 10.9447 3.93073 10.7935 4.08195L5.79321 9.09394L3.20227 6.50911C3.0506 6.35789 2.80561 6.35789 2.65439 6.50954C2.5032 6.66076 2.50365 6.90576 2.65487 7.0569L5.52023 9.91526C5.6719 10.0665 5.91689 10.0665 6.06808 9.91479Z" fill="#00a060"/>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="adv-item">
                                        <span className="adv-item-tit">Expiry Date</span>
                                        <span className="adv-item-detail">
                                            <span>{item.plan_expired == 0 ? window.confirm.number_day + ' Days' : item.plan_expired + ' Days'}</span>
                                        </span>
                                    </div>
                                    <div className="adv-item">
                                        <span className="adv-item-tit">Expiry Credit</span>
                                        <span className="adv-item-detail">
                                            <span>{"Until Credit = 0"}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="package-btn">
                                    <a onClick={() => checkBuyAnother(item)} className="btn residential__pay_btn">Buy Plan</a>
                                    {
                                        item?.discount ? (
                                            <span className="actlabel_coner d-block text-danger mt-1">SALE OFF {item?.discount.discount}%</span>
                                        ) : ""
                                    }
                                    {
                                        item?.discount && item?.discount?.end_date ? (
                                            <CountdownTimer targetDate={item?.discount.end_date} show="Expired" />
                                        ) : ""
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <Modal visible={isModalVisiblePlan} footer={null} closable={false} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <div className='alert alert-warning'>WARNING!</div>
                        <p>You're current using plan ({profile?.plan_current_name}), so you only renew plan ({currentPlan?.plan_name}), can't upgrade new plan.</p>
                        <p>If you buy another plan , Old plan will be canceled please waiting for the current plan to expire.</p>
                        <p>If you are sure you want to buy another package, please click buy another package</p>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleBuyAnother} className="d-block medium">Buy another package</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={() => setIsModalVisiblePlan(false) } className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default PremiumList;
